import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-box',
  template: `
    <div style="padding: 5px;">
      <div mat-dialog-content><img src="./assets/error.png" height="50px" width="50px">
      &nbsp; User Name already exists.</div>
     
    </div>
  `,
})
export class UserNameExistsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
