import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSetComponent } from './data-set/data-set.component';
import { MatIconModule } from '@angular/material/icon';
import { PouchAddComponent } from './pouch-add/pouch-add.component';
import { BoxAddComponent } from './box-add/box-add.component';
import { FileEditComponent } from './file-edit/file-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { DataDialogComponent } from './data-dialog/data-dialog.component';
import { ReportsComponent } from './reports/reports.component';
import { MisReportsComponent } from './mis-reports/mis-reports.component';
import { CheckAllEditComponent } from './data-dialog/check-all-edit.component';
import { DataEntryForAdminComponent } from './data-entry-for-admin/data-entry-for-admin.component';




@NgModule({
  declarations: [
    DataSetComponent,
    PouchAddComponent,
    BoxAddComponent,
    FileEditComponent,
    DataDialogComponent,
    ReportsComponent,
    MisReportsComponent,
    CheckAllEditComponent,
    DataEntryForAdminComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatIconModule
  ]
})
export class PodModule { }
