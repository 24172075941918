import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';
import { RoleMasterService } from '../role-master.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.scss']
})
export class RoleAddComponent implements OnInit {

  constructor(
     private loc: Location,
    private fb: FormBuilder,
    private roleService: RoleMasterService,
    private router: Router,
    private sharedService: SharedService
    ) { }
    roleData: FormGroup;

    goBack(){
    this.loc.back();
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  ngOnInit(): void {
    this.roleData= this.fb.group({
      name: ['', Validators.required],
      selectedItems: [[]],
      //status: [true],
    })
    this.roleService.GetMenuDropdown().subscribe(
      data => {
        this.dropdownList = []

        data.masterDetails.listMenu.forEach(element => {
          this.dropdownList.push({item_id: element.id, item_text: element.name})
        });
      }
    )

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  add_role(){
    // console.log(this.roleData)
    this.sharedService.visible(true);
    this.roleService.addRole(this.roleData).subscribe(
      data => {
        this.router.navigate(['/role-list'])
        this.sharedService.visible(false);
      },
      error => {
        // console.log(error)
        this.sharedService.visible(false);
      }
    )
  }

  get f() {return this.roleData.controls; }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

}
