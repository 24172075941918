import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { PODNumberDeleteSuccessComponent } from '../pod-number-delete/pod-number-delete-success.component';
import { PODNumberDeleteComponent } from '../pod-number-delete/pod-number-delete.component';
import { PodNumberMasterService } from '../pod-number-master.service';

@Component({
  selector: 'app-pod-number-list',
  templateUrl: './pod-number-list.component.html',
  styleUrls: ['./pod-number-list.component.scss']
})
export class PodNumberListComponent implements OnInit {

  constructor(
    private router: Router,
    private podService: PodNumberMasterService,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) { }

  displayedColumns: string[] = ['name', 'status', 'action'];

  dataSource = new MatTableDataSource([]);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngOnInit(): void {
    this.sharedService.visible(true);
    this.podService.getPodNumberList().subscribe(
      data => {
        // console.log(data.masterDetails.listPOD);
        this.dataSource = new MatTableDataSource(data.masterDetails.listPOD);
          this.ngAfterViewInit()
        this.sharedService.visible(false)
      },
      error => {
        // console.log(error);
        if (error instanceof HttpErrorResponse) {
          if (error.status === 403) {
            this.router.navigate(['/login']);
          }
          if (error.status === 500) {
            this.router.navigate(['/login']);
          }
        }
        this.sharedService.visible(false);
      }
    )
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  pod_edit(id){
    this.router.navigate(['/pod-add', id]);
  }

  pod_details(id){
    this.router.navigate(['/pod-details' , id]);
  }

  pod_delete(id) {
    const dialogRef = this.dialog.open(PODNumberDeleteComponent, { data: { id: id } });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.sharedService.visible(true);
        this.podService.deletePodNumber(result).subscribe(
            data => {
              // console.log(data);
              this.sharedService.visible(false);
            },
            error => {
              // console.log(error)
              this.sharedService.visible(false);
            }
          )

          const successDelete = this.dialog.open(PODNumberDeleteSuccessComponent)
          successDelete.afterClosed().subscribe(
            result => {
              this.sharedService.visible(true);
              this.podService.getPodNumberList().subscribe(
                  data => {
                    this.dataSource = new MatTableDataSource(data.masterDetails.listPOD);
                    this.ngAfterViewInit();
                    // console.log(data.masterDetails.roleList);

                    if (this.displayedColumns.length === 0) {
                      this.displayedColumns = Object.keys(data[0]);
                    }
                    this.sharedService.visible(false);
                  },
                  error => {
                    if (error instanceof HttpErrorResponse) {
                      if (error.status === 403) {
                        this.router.navigate(['/login']);
                      }
                      if (error.status === 500) {
                        this.router.navigate(['/login']);
                      }
                    }
                    this.sharedService.visible(false);
                  });

            }
          )
      }
    });
  }
}
