<div class="container">
  <div class="user-box">
    Add Role
  </div>
  <form [formGroup]="roleData" (ngSubmit)="add_role()">
    <br>
    <div class="row" style="text-align: left;">
      <div class="col-md-4">
        <div class="form-group" style="margin-top: 13px;">
          <label>Role Name</label>
          <input class="form-control" formControlName="name" placeholder="Enter role name..." required>
        </div>
      </div>
      <div class="col-md-4" style="margin-top: 13px;">
        <label>Select Menu</label>
        <ng-multiselect-dropdown [placeholder]="'Select Menu'" [settings]="dropdownSettings"
          [data]="dropdownList" formControlName="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <br>
    <!-- <div class="my-auto" style="text-align: left;">
      <label class="switch">
        <input type="checkbox" formControlName="status">
        <span class="slider round my-auto"></span>
      </label> &nbsp; Status<br><br><br>
    </div> -->
    <div style="text-align: left;">
      <button type="submit" class="btn btn-save" [disabled]="!roleData.valid">
        <mat-icon style="font-size: 14px; font-weight: bold;">add</mat-icon>Save
      </button>
      <button type="button" (click)="goBack()" class="btn btn-danger btn-back" style="margin-left: 8px;">
        <mat-icon style="position: relative; top: 5px;">first_page</mat-icon> Back
      </button>
    </div>
  </form>
</div>
<br><br><br>
<br><br><br>
<br><br><br><br><br><br>