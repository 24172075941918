import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { PodNumberMasterService } from '../pod-number-master.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-pod-number-edit',
  templateUrl: './pod-number-edit.component.html',
  styleUrls: ['./pod-number-edit.component.scss']
})
export class PodNumberEditComponent implements OnInit {

  constructor(
    private router: Router,
    private podService: PodNumberMasterService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private loc: Location,
    private fb : FormBuilder
  ) { }

  public id = parseInt(this.route.snapshot.paramMap.get('id'));
  podData: FormGroup;
  showError: any;

  goBack(){
    this.loc.back();
  }

  ngOnInit(): void {

    this.podData= this.fb.group({
      name: ['', Validators.required],
      status: [true],
    })

    this.sharedService.visible(true);
    this.podService.getPodNumberList().subscribe(
      data => {
        var listdata = data.masterDetails.listPOD;
        var index = listdata.find(l => l.id === this.id);
        if(index === undefined){
          this.router.navigate(['/pod-list']);
        }
        this.podData= this.fb.group({
          name: [index.podNumber, Validators.required],
          status: [index.status],
        })
        // console.log(index);
        this.sharedService.visible(false);
      },
      error => {
        // console.log(error);
        this.sharedService.visible(false);
      }
    )

  }

  update_pod_number(){
    this.podService.updatePodNumber(this.id , this.podData.value).subscribe(
      data => {
        // console.log(data);
        if(data.message === 'POD Updated'){
          this.router.navigate(['/pod-list']);
        }else{
          this.showError = data.message;
        }
        this.sharedService.visible(false);
      },
      error => {
        // console.log(error);
        this.sharedService.visible(false);
      }
    )
  }

}
