import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserMasterService {

  link = environment.link

  user = "";
  constructor(private http: HttpClient) { }
  getUserList(): Observable<any> {
    let data = {
      "MASTERHEAD": {
        "UserId": 3
      },
      "MASTERDETAILS": {

      }
    }
    return this.http.post<any>(this.link + "api/Account/ListUser", data);
  }

  userAdd(data): Observable<any> {
    let d = {
      "MASTERHEAD": {
        "UserId": localStorage.getItem("userId")
      },
      "MASTERDETAILS": {
        "User": {
          "FirstName": data.get("FirstName").value,
          "MiddleName": data.get("MiddleName").value,
          "LastName": data.get("LastName").value,
          "MobileNumber": data.get("MobileNumber").value,
          "Email": data.get("Email").value,
          "UserName": data.get("UserName").value,
          "listroles": data.value.listroles,
          "PasswordHash": data.get("PasswordHash").value
        }
      }
    }
    // console.log(d)
    return this.http.post<any>(this.link + "api/Account/CreateUser", d);
  }

  userDetails(data){
    debugger;
    this.user = data;
  }

  userEdit(data, id): Observable<any> {
    let d = {
      "MASTERHEAD": {
          "UserId": localStorage.getItem("userId")
      },
      "MASTERDETAILS":{
          "User":{
              "Id": id,
              "FirstName": data.get("FirstName").value,
              "MiddleName": data.get("MiddleName").value,
              "LastName": data.get("LastName").value,
              "MobileNumber": data.get("MobileNumber").value,
              "Email": data.get("Email").value,
              "UserName": data.get("UserName").value,
              "listroles": data.value.listroles,
              // "PasswordHash": data.get("PasswordHash").value
          }
      }
  }
    // console.log(d)
    return this.http.post<any>(this.link + "api/Account/UpdateUser", d);
  }

  getUser(id): Observable<any> {
    var d = {
      "MasterHead": {
        "Id": id
      }
    }
    return this.http.post<any>(environment.link + 'api/Account/GetUser', d);
  }


}
