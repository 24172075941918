<div class="container" style="width: 800px;">
    <a mat-dialog-close type="button" class="btn modal-close" style="float: right;">
        <span style="color: rgb(255, 148, 148);" aria-hidden="true">&times;</span>
      </a>
    <form [formGroup]="pouchData">
    <div class="row">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-4"><h2>Pouch Name</h2></div>
                <div class="col-md-8">
                    <div class="form-group">
                        <input formControlName="name" class="form-control" id="name" placeholder="Pouch Name">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <button class="btn btn-sm btn-primary" (click)="pouchAdd()">
                Add Pouch And Push Files
            </button>
        </div>
    </div>
    </form>
    <br><br>
    <h3>Recent Pouches</h3>
    <div class="row">
        <div class="col-md-3"><b>Pouches</b></div>
        <div class="col-md-3"><b>Files</b></div>
        <div class="col-md-4"></div>
    </div>
    <br>
    <form [formGroup]="pouchEditData">
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="row" *ngFor="let t of recentPouches; let i=index;" formArrayName="pouch">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-8">
                                <input class="form-control" formControlName="{{i}}" style="position: absolute;" id="name" placeholder="Pouch Name" [readonly]="recentPouchOp[i]">
                            </div>
                            <div class="col-1 p-0">
                                <mat-icon *ngIf="recentPouchOp[i]" style="color: blue; cursor: pointer; font-size: 15px;" (click)="activate_readOnly(i)">edit</mat-icon>
                                <mat-icon *ngIf="!recentPouchOp[i]" style="color: blue; cursor: pointer; font-size: 18px;" (click)="edit_pouch(i, t.pouchId)">done</mat-icon>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    {{t.clientIdCount}}
                                    <!-- <input class="form-control" value={{t.clientIdCount}} id="count" readonly> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-sm btn-primary" (click)="pouchEdit(t.pouchId, t.pouchName)">
                            Push Files
                        </button>
                        <button class="btn btn-sm btn-danger" style="margin-left: 10px;" (click)="showGroup(t)">
                            Groups
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4" style="height: 150px; overflow-y: auto;">
                <table style="width: 100%;">
                    <tr>
                        <th>Group Number</th>
                        <th>Remove</th>
                    </tr>
                    <tr *ngFor="let g of groups; let i=index;">
                        <td>{{g.groupName}}</td>
                        <td><mat-icon style="color: crimson; cursor: pointer;" (click)="removeGroup(g, i)">delete</mat-icon></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    

    </form>
</div>