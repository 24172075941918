<div class="form-horizontal">
  <form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate>
    <mat-card class="basicCard">
      <mat-card-header>
        <mat-card-title class="card-container-left"> Client Upload</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <!-- <app-upload (onUploadFinished)="uploadFinished($event)"></app-upload> -->

        <div class="row" style="margin-bottom:15px;">
          <div class="col-md-12">
            <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;" [accept]="extAccepts">
        
        
            <span *ngIf="progress>0">{{progress}}%</span>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <button type="button" class="btn btn-info" (click)="file.click()">Select File</button>
                </div>
                <div class="col-md-8">
                  <span class="upload" *ngIf="progress > 0">
                    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
                  </span>
                </div>
              </div>
            </div>
        
        
            <span class="upload" *ngIf="message">
              {{message}}
            </span>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">

            <div class="col-md-4">

              <div class="form-group" [hidden]="true">
                <label>File Name</label>
                <input class="form-control" formControlName="filePath" placeholder="Upload" required value="response.filePath"
                   required>
              </div>

            </div>

          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary" [disabled]="save_called || progress<100"><i class="fa fa-upload"></i>&nbsp;&nbsp;Submit</button>
        </div>
      </mat-card-actions>
    </mat-card>
    <mat-progress-bar mode="indeterminate" *ngIf="isCompleted"></mat-progress-bar>
  </form>
</div>

<br><br><br><br>
<br><br><br><br>
<br><br><br><br>
<br><br><br><br>
