<div class="container">
    <div class="user-box">

      <div class="row">
        <div class="col-9 my-auto" >
          Edit User
        </div>
        <div class="col-3" style="text-align: right;" fxHide.lt-md="true">
        </div>
        <div class="col-3" style="text-align: center; padding: 0;" fxHide.gt-sm="true">
        </div>
      </div>
    </div>
    <br>

    <form [formGroup]="userData" (ngSubmit)="add_user()">
      <br>
    <div class="row" style="text-align: left;">

        <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>First Name</label>
            <input class="form-control" formControlName="FirstName" placeholder="Enter First Name..." >
          </div>
          <span *ngIf="!userData.get('FirstName').valid && userData.get('FirstName').touched" class="help-block" style="color:brown"> Please Enter First Name </span>
        </div>
        <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>Middle Name</label>
            <input class="form-control" formControlName="MiddleName" placeholder="Enter Middle Name..." >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>Last Name</label>
            <input class="form-control" formControlName="LastName" placeholder="Enter Last Name...">
          </div>
          <span *ngIf="!userData.get('LastName').valid && userData.get('LastName').touched" class="help-block" style="color:brown"> Please Enter Last Name </span>
        </div>
        <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>Email Address</label>
            <input class="form-control" formControlName="Email" placeholder="Enter Email address...">
          </div>
          <span *ngIf="!userData.get('Email').valid && userData.get('Email').touched" class="help-block" style="color:brown"> Please Enter Valid Email Address </span>
        </div>

        <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>Mobile Number</label>
            <input class="form-control" formControlName="MobileNumber" placeholder="Enter Mobile Number...">
          </div>
          <span *ngIf="!userData.get('MobileNumber').valid && userData.get('MobileNumber').touched" class="help-block" style="color:brown"> Please Enter Valid Mobile no. </span>
        </div>

        <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>Username</label>
            <input class="form-control" formControlName="UserName" placeholder="Enter Username..." readonly>
          </div>
          <span *ngIf="!userData.get('UserName').valid && userData.get('UserName').touched" class="help-block" style="color:brown"> Please Enter Valid Username. </span>
        </div>
        <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>Select Role</label>
            <div style="background-color: white;">
              <ng-multiselect-dropdown
              style="background-color: white;"
                [placeholder]="'Select Role'"
                [settings]="dropdownSettings"
                [data]="dropdownList"
                [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                [ngModelOptions]="{standalone: true}"
              >
              </ng-multiselect-dropdown>
              </div>
            <!-- <select class="form-control" formControlName="listroles">
              <option value="2">Admin</option>
            </select> -->
            <!-- <select formControlName="listroles" class="form-control">               -->
              <!-- <option value="0" selected>Select Role</option> -->
              <!-- <option *ngFor="let role of roleList" value={{role.value}}>{{role.name}}</option> -->
          <!-- </select> -->
          </div>
          <span *ngIf="!userData.get('listroles').valid && userData.get('listroles').touched" class="help-block" style="color:brown"> Please Select Role </span>
        </div>

        <!-- <div class="col-md-4">
          <div class="form-group" style="margin-top: 13px;">
            <label>Password</label>
            <input type="password" class="form-control" formControlName="PasswordHash" placeholder="Enter Password...">
          </div>
          <span *ngIf="!userData.get('PasswordHash').valid && userData.get('PasswordHash').touched" class="help-block" style="color:brown"> Please Enter Valid Password. </span>
        </div> -->

    </div>

    <div style="text-align: left;">
      <button type="submit" class="btn btn-save btn-default" [disabled]="!userData.valid"><mat-icon style="font-size: 14px; font-weight: bold;">add</mat-icon>Save</button>
      <!-- <button type="button" class="btn btn-danger btn-back" style="margin-left: 8px;"><mat-icon style="position: relative; top: 5px;">first_page</mat-icon> Back</button> -->
      <button type="button" (click)="goBack()" class="btn btn-danger btn-back" style="margin-left: 8px;">
        <mat-icon style="position: relative; top: 5px;">first_page</mat-icon> Back
      </button>
    </div>
    </form>
  </div>

  <br><br><br><br><br><br><br><br>
