<div class="container" style="text-align: left;">
  <div class="user-box">
    POD Number Details
  </div>
  <br>
  <table>
    <tbody>
      <tr>
        <th>POD Number :- </th>
        <td>{{ podNumber }}</td>
      </tr>
      <tr>
        <th>Status :- </th>
        <td>{{ podStatus }}</td>
      </tr>
    </tbody>
  </table>
  <br>
  <button type="button" (click)="goBack()" class="btn btn-danger btn-back" style="margin-left: 8px;">
    <mat-icon style="position: relative; top: 5px;">first_page</mat-icon> Back
  </button>

</div>
