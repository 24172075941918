import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DataSetComponent } from '../data-set/data-set.component';
import { PodService } from '../pod.service';

@Component({
  selector: 'app-box-add',
  templateUrl: './box-add.component.html',
  styleUrls: ['./box-add.component.scss']
})
export class BoxAddComponent implements OnInit {

  constructor(
    private podService: PodService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DataSetComponent>
  ) { }

  boxData: FormGroup;
  boxEditData: FormGroup;

  recentBoxes = []
  recentBoxOp = [true, true, true]

  ngOnInit(): void {
    this.boxEditData = this.fb.group({
      box: this.fb.array(['', '', ''])
    })

    this.boxData = this.fb.group({
      name: ["", Validators.required]
    })
    this.podService.getRecentBoxes().subscribe(
      data => {
        //console.log(data)
        this.recentBoxes = data.masterDetails.listCarton
        const items = (<FormArray>this.boxEditData.get("box"));
        for(let i=0; i<this.recentBoxes.length; i++){
          items.at(i).patchValue(this.recentBoxes[i].cartonName);
        }
      }
    )
  }
  add_box(){
    if(!this.boxData.valid){
      return false;
    }
    this.podService.add_box(0, this.boxData.get("name").value).subscribe(
      data => {
        // console.log(data)
        this.dialogRef.close()
      }
    )
  }
  edit_box(id, name){
    this.podService.add_box(id, name).subscribe(
      data => {
        // console.log(data)
        this.dialogRef.close()
      }
    )
  }

  activate_readOnly(i){
    this.recentBoxOp[i] = false;
  }

  editBox(i, id){
    this.recentBoxOp[i] = true;
    this.podService.editBox(this.boxEditData.get("box").value[i], id).subscribe(
      data => {
        // console.log(data)
      }
    )
  }

}
