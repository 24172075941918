<div class="container" style="text-align: left;">
    <form [formGroup]="reportData">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="cname">From Date</label>
                    <input type="date" class="form-control" formControlName="from" id="cname">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="cname">To Date</label>
                    <input type="date" class="form-control" formControlName="to" id="cname">
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary" (click)="filter()">Filter</button>
        <button class="btn btn-primary" style="float: right;" (click)="exportexcel()">Export Excel</button>
    </form>
</div>

<br><br>
<div class="container">
    <table class="table" id="excel-table">
        <tr>
            <td>Date</td>
            <td>Processed Client ID Count.</td>
        </tr>
        <tr *ngFor="let r of reports;">
            <td>{{r.date.split("T")[0]}}</td>
            <td>{{r.count}}</td>
        </tr>
    </table>
</div>

<br><br><br><br><br><br><br><br><br><br><br><br><br><br>