import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PodService {

  constructor(private http: HttpClient) { }

  link = environment.link;

  getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }

  getRecords(data): Observable<any> {
    let d = {
      "masterHead": {
        "GroupName": data.get("search").value
      }
    }
    return this.http.post<any>(this.link + "api/Data/GetRecords", d);
  }

  getPouchs(): Observable<any> {
    let data = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }
    }
    return this.http.post<any>(this.link + "api/Data/PopulatePouch", data, { headers: this.getHeader() });
  }

  getboxes(): Observable<any> {
    let data = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }
    }
    return this.http.post<any>(this.link + "api/Data/PopulateBox", data, { headers: this.getHeader() });
  }

  fileEdit(id, data, podData) {
    let d = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }, "masterDetails": {
        "clientInfo": {
          "clientInfoId": id,
          "UCG": data.get("UCG").value,
          "DPN": data.get("DPN").value,
          "applicationForm": data.get("applicationForm").value,
          "CLIForm": data.get("CLIForm").value,
          "KYCCopy": data.get("KYCCopy").value,
          "newRequestForm": data.get("newRequestForm").value,
          "PODNumber": podData.get("podNumber").value,
          "receivedDate": new Date()
        }
      }
    }
    // console.log(d);
    return this.http.post<any>(this.link + "api/Data/SaveClient", d, { headers: this.getHeader() });
  }

  getStatusList(): Observable<any> {
    let data = {

    }
    return this.http.post<any>(this.link + "api/Data/GetDocumentStatus", data, { headers: this.getHeader() });
  }

  getRecentPouches(): Observable<any> {
    let data = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }
    }
    return this.http.post<any>(this.link + "api/Data/TopPouches", data, { headers: this.getHeader() });
  }

  getRecentBoxes(): Observable<any> {
    let data = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }
    }
    return this.http.post<any>(this.link + "api/Data/TopBoxes", data, { headers: this.getHeader() });
  }

  add_box(id, name) {
    let d = {
      "masterHead": {
        "userId": localStorage.getItem("userId")

      }, "masterDetails": {
        "carton": {
          "cartonId": id,
          "cartonName": name
        },
        "pouch": {

        }
      }
    }
    return this.http.post<any>(this.link + "api/Data/AddBox", d, { headers: this.getHeader() });
  }

  add_pouch(id, name) {
    let d = {
      "masterHead": {
        "userId": localStorage.getItem("userId")

      }, "masterDetails": {
        "pouch": {
          "pouchId": id,
          "pouchName": name
        }
      }
    }
    return this.http.post<any>(this.link + "api/Data/AddPouch", d, { headers: this.getHeader() });
  }

  allClientCheck(search, pod): Observable<any> {
    let data = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }, "masterDetails": {
        "groupNumber": {
          "groupName": search.get("search").value,
          "PODNumber": pod.get("podNumber").value,
          "receivedDate": pod.get("receivedDate").value
        }
      }
    }
    return this.http.post<any>(this.link + "api/Data/GroupCheck", data, { headers: this.getHeader() });
  }

  editPouch(data, id): Observable<any> {
    let d = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }, "masterDetails": {
        "pouch": {
          "pouchId": id,
          "pouchName": data

        }
      }
    }
    return this.http.post<any>(this.link + "api/Data/UpdatePouch", d, { headers: this.getHeader() });
  }

  editBox(data, id): Observable<any> {
    let d = {
      "masterHead": {
          "userId" : 2
      },"masterDetails": {
          "carton": {
            "cartonId" : id,
            "cartonName" : data

          }
      }
    }
    return this.http.post<any>(this.link + "api/Data/UpdateBox", d, { headers: this.getHeader() });
  }


  filterData(data): Observable<any> {
    let d = {
      "masterHead": {
      },"masterDetails": {
          "filter": {
          "clientID": "",
          "cartonName": data.get("cartonName").value,
          "pouchName": data.get("pouchName").value,
          "groupName": data.get("groupName").value,
          "fromDate": data.get("from").value,
          "toDate": data.get("to").value
          }
      }
    }
    return this.http.post<any>(this.link + "api/Data/FilterData", d, { headers: this.getHeader() });
  }

  misreport(data): Observable<any> {
    let d = {
      "masterHead": {
          //"GroupName":"3422639"
      },"masterDetails": {
          "filter": {
          "fromDate": data.get("from").value,//"2021-06-15",
          "toDate": data.get("to").value //"2021-07-02"
          }
      }
    }
    return this.http.post<any>(this.link + "api/Data/MISReport", d, { headers: this.getHeader() });
  }

  groupCheck(data): Observable<any> {
    let d = data
    return this.http.post<any>(this.link + "api/Data/GroupCheck", d, { headers: this.getHeader() });
  }

  removeGroupFromPouch(id): Observable<any> {
    let d = {
      "masterHead": {
          "UserId":localStorage.getItem("userId"),
          "Id": id
      }
    }
    return this.http.post<any>(this.link + "api/Data/RemoveGroupFromPoch", d, { headers: this.getHeader() });
  }

  getPODDropDownList(): Observable<any>{
    let d = {
      "masterHead": {
          "UserId":"1"
      }
    }
    return this.http.post<any>(this.link + 'api/Data/PODList',d);
  }



}
