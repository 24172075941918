import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { PagingParameterModel } from 'src/app/pagingparametermodel';
import { Clientupload } from '../model/clientupload';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ClientuploadService {
  // myAppUrl: string;
  // myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };
  myAppUrl:string;
  myApiUrl:string;
  constructor(private http: HttpClient) { 

    // this.myAppUrl = 'https://dataentryapi.mylocalo.com/';
    this.myAppUrl = environment.link;
    this.myApiUrl = 'api/data/UploadFile';
  }
  // constructor(private http: HttpClient) {
  //   this.myAppUrl = environment.appUrl;
  //   this.myApiUrl = 'api/Clients/';
  // }

  getUploadFile(): Observable<any> {
    debugger;
    let data = {
      "ClientInfo": {
        "UserId" : 2
      }
    }
    // return this.http.post<any>("https://dataentryapi.mylocalo.com/" + "api/Data/UploadFile", data);
    return this.http.post<any>(environment.link + "api/Data/UploadFile", data);
  }
  uploadFiles(uploadFiles: any): Observable<Clientupload> {
    debugger;
    return this.http.post<Clientupload>(this.myAppUrl + this.myApiUrl, JSON.stringify(uploadFiles), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  








  // getDSOList(pageNo: number, pageSize: number): Observable<PagingParameterModel> {
  //   return this.http.get<any>(this.myAppUrl + this.myApiUrl + "GetClientList" + "/" + pageNo + "/" + pageSize)
  //     .pipe(
  //       catchError(this.errorHandler)
  //     );
  // }

  uploadFile(uploadFiles: any): Observable<Clientupload> {
    debugger;
    var data= uploadFiles;
    console.log(data)
    return this.http.post<Clientupload>(environment.link +"api/Data/UploadFile", data, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  /*errorHandler*/
  errorHandler(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
