<br><br>
<div class="container" style="text-align: left;">
  <div class="row">
    <div class="col-md-5" style="padding: 30px;">
      <form [formGroup]="userData" (ngSubmit)="logIn()">
        <br><br>
        <div class="form-group">
          <label for="userName">User Name</label>
          <input type="text"  class="form-control input-box" id="userName" formControlName="userName" placeholder="Enter User Name">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" formControlName="password" class="form-control input-box" id="password" placeholder="Enter Password">
          <div *ngIf="errorFlag" style="padding: 5%;" class="alert alert-danger">
            You have entered wrong username or password
          </div>
        </div>
        <button type="submit" class="btn btn-login">Login</button>
      </form>
    </div>
    <div class="col-md-7" style="padding: 20px;">
      <img src="assets/data_entry.jpg" height="100%" width="100%">
    </div>
  </div>
</div>

<br><br><br>
