import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DataSetComponent } from '../data-set/data-set.component';
import { PodService } from '../pod.service';

@Component({
  selector: 'app-pouch-add',
  templateUrl: './pouch-add.component.html',
  styleUrls: ['./pouch-add.component.scss']
})
export class PouchAddComponent implements OnInit {

  constructor(
    private podService: PodService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DataSetComponent>
  ) { }
  pouchData: FormGroup;
  recentPouches = []
  groups = [];
  recentPouchOp = [true, true, true]
  pouchEditData: FormGroup;

  ngOnInit(): void {
    this.pouchEditData = this.fb.group({
      pouch: this.fb.array(['', '', ''])
    })

    this.pouchData = this.fb.group({
      name: ["", Validators.required]
    })
    this.podService.getRecentPouches().subscribe(
      data => {
        // console.log(data)
        this.recentPouches = data.masterDetails.listPouch
        const items = (<FormArray>this.pouchEditData.get("pouch"));
        for (let i = 0; i < this.recentPouches.length; i++) {
          items.at(i).patchValue(this.recentPouches[i].pouchName);
        }
      }
    )
  }

  pouchAdd() {
    if (!this.pouchData.valid) {
      return false;
    }
    this.podService.add_pouch(0, this.pouchData.get("name").value).subscribe(
      data => {
        // console.log(data)
        this.dialogRef.close()
      }
    )
  }

  pouchEdit(id, name) {
    this.podService.add_pouch(id, name).subscribe(
      data => {
        // console.log(data)
        this.dialogRef.close()
      }
    )
  }
  activate_readOnly(i) {
    this.recentPouchOp[i] = false;
  }

  edit_pouch(i, id) {
    this.recentPouchOp[i] = true;
    this.podService.editPouch(this.pouchEditData.get("pouch").value[i], id).subscribe(
      data => {
        // console.log(data)
      }
    )
  }
  showGroup(pouch) {
    // console.log(pouch)
    this.groups = pouch.groupNumber
  }

  removeGroup(group, i) {
    // console.log(group, i)
    this.podService.removeGroupFromPouch(group.groupNumberId).subscribe(
      data => {
        // console.log(data)
        if (data.message == "Record Removed Successfully.") {
          this.groups.splice(i, 1);
          this.podService.getRecentPouches().subscribe(
            data => {
              // console.log(data)
              this.recentPouches = data.masterDetails.listPouch
              const items = (<FormArray>this.pouchEditData.get("pouch"));
              for (let i = 0; i < this.recentPouches.length; i++) {
                items.at(i).patchValue(this.recentPouches[i].pouchName);
              }
            }
          )
        } else{
          alert(data.message)
        }
      }
    )
  }

}
