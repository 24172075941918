
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-delete',
  template: `
    <div style="padding: 5px;">
      <div mat-dialog-content>
        <img src="./assets/success.png" height="45px" width="45px">
        Record has been deleted successfully.
      </div>
      <div mat-dialog-actions style="float: right;">
        <button class="btn btn-sm btn-secondary" mat-button mat-dialog-close>Ok</button>
      </div>
    </div>
  `,
})
export class PODNumberDeleteSuccessComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }
}
