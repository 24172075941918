import { Component, OnInit } from '@angular/core';
import { LayoutService } from './layout/layout.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Data-Entry';

  constructor(
    private layoutService : LayoutService
  ){}

  ngOnInit():void{
      if (localStorage.getItem('menu') != null) {
        var menu = CryptoJS.AES.decrypt(localStorage.getItem('menu'), 'secretKey').toString(CryptoJS.enc.Utf8)
        //console.log(JSON.parse(menu));
        this.layoutService.getMenuWhenRefresh(JSON.parse(menu))
    }
  }
}
