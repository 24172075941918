import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PodNumberMasterService {

  constructor(
    private http: HttpClient
  ) { }

  link = environment.link;
  pod: any = null;

  getPodNumberList(): Observable<any> {

    let data = {
      "MASTERHEAD": {
      }
    }
    return this.http.post<any>(this.link + "api/POD/ListPOD" , data);
  }

  createPodNumber(podNumber) : Observable<any>{

    let data = {
      "MASTERHEAD": {
          "UserId": 1
      },
      "MASTERDETAILS":{
          "POD":{
              "PODNumber": podNumber
          }
      }
    }

    return this.http.post<any>(this.link + "api/POD/CreatePOD" , data);
  }

  updatePodNumber(id , podData): Observable<any> {
    let data = {
      "MASTERHEAD": {
            "UserId": 1
      },
      "MASTERDETAILS":{
           "POD":{
               "Id": id,
              "PODNumber": podData.name,
              "Status": podData.status
          }
      }
    }
    // return;
    return this.http.post<any>(this.link + "api/POD/UpdatePOD" , data);
  }

  deletePodNumber(id): Observable<any>{
    let data = {
      "MASTERHEAD": {
          "Id": id
      }
    }

    return this.http.post<any>(this.link + "api/POD/DeletePOD" , data);
  }
}
