import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserMasterService } from '../user-master.service';
import { Router } from '@angular/router';
import { RoleMasterService } from 'src/app/role-master/role-master.service';
import { MatDialog } from '@angular/material/dialog';
import { UserNameExistsComponent } from '../usernameExists';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {
  roleList: any = [0]

  constructor(
    private loc: Location,
    private fb: FormBuilder,
    private userService: UserMasterService,
    private router: Router,
    private roleservice: RoleMasterService,
    public dialog: MatDialog
  ) { }

  userData: FormGroup;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings : IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  goBack() {
    this.loc.back();
  }

  ngOnInit(): void {
    this.roleservice.GetRoleDropdown().subscribe(result => {
      this.dropdownList = result.dropDown.map(
        data => {
          return {
            item_id : data.value,
            item_text : data.name,
          }
        }
      )
      // console.log(this.dropdownList);
    });

    this.userData= this.fb.group({
      FirstName: ['', Validators.required],
      MiddleName: [''],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required , Validators.email]],
      MobileNumber: ['', [Validators.required,Validators.minLength(10), Validators.maxLength(10),Validators.pattern(new RegExp("[0-9 ]{10}"))]],
      UserName: ['', Validators.required],
      listroles: [null],
      PasswordHash: ["", [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    })
  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }


  add_user(){
    var list = this.selectedItems.map(
      data => {
        return data.item_id;
      }
    )

    if(list.length <= 0){
      return false;
    }

    this.userData.value.listroles = list;

    if(!this.userData.valid){
      return false;
    }

    // console.log(this.userData);
    this.userService.userAdd(this.userData).subscribe(
      data => {
        // console.log(data)
        if(data.message == "User Created"){
          this.router.navigate(["user-list"])
        }
        else if(data.message == "User already Exits"){
          console.log(data);
          const dialogRef = this.dialog.open(UserNameExistsComponent);
          dialogRef.afterClosed().subscribe(data => {
            this.router.navigate(["user-add"]);
          });
        }
      }
      ,error => {
        // console.log(error);
      }
    )
  }

}
