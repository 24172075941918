import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { PodNumberAddComponent } from './pod-number-add/pod-number-add.component';
import { PodNumberEditComponent } from './pod-number-edit/pod-number-edit.component';
import { PodNumberListComponent } from './pod-number-list/pod-number-list.component';
import { PodNumberDetailsComponent } from './pod-number-details/pod-number-details.component';
import { PODNumberDeleteComponent } from "./pod-number-delete/pod-number-delete.component";
import { PODNumberDeleteSuccessComponent } from "./pod-number-delete/pod-number-delete-success.component";

@NgModule({
  declarations: [
    PodNumberAddComponent,
    PodNumberEditComponent,
    PodNumberListComponent,
    PodNumberDetailsComponent,
    PODNumberDeleteComponent,
    PODNumberDeleteSuccessComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class PodNumberMasterModule { }
