import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { PodModule } from './pod/pod.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './auth/interceptor.service';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { MatIconModule } from '@angular/material/icon';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RoleMasterModule } from './role-master/role-master.module';
import { UserMasterModule } from './user-master/user-master.module';
import { UploadComponent } from './upload/upload.component';
import { ClientuploadComponent } from './client-upload/client-upload/clientupload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { FileSizeExcidedComponent } from './client-upload/client-upload/file-size-excided.component';
import { PodNumberMasterModule } from './pod-number-master/pod-number-master.module';


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    NavbarComponent,
    SidebarComponent,
    UploadComponent,
    ClientuploadComponent,
    FileSizeExcidedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NoopAnimationsModule,
    PodModule,
    HttpClientModule,
    AuthModule,
    SharedModule,
    MatSidenavModule,
    PodNumberMasterModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RoleMasterModule,
    UserMasterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatDialogModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
