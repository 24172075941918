import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  link = environment.link

  login(data): Observable<any> {
    let d = {
        "MASTERHEAD": {
          "username" : data.get("userName").value,
       "password" : data.get("password").value
        },
        "MASTERDETAILS": {
      }
    }
    return this.http.post<any>(this.link+"api/Authentication/Login", d, { headers: {}});
  }

  isLoggedIn() {
    return !!localStorage.getItem('token');
  }

  logoutUser() {
    localStorage.clear();
    this.router.navigate(['/login'])
  }

  redirectUser(){
    if (localStorage.getItem('menu') != null) {
      var menu = CryptoJS.AES.decrypt(localStorage.getItem('menu'), 'secretKey').toString(CryptoJS.enc.Utf8)
      var menulist = JSON.parse(menu);
      var link = menulist[0].link
      this.router.navigate([link]);
    }
  }


  getToken() {
    return localStorage.getItem('JWT_TOKEN');
  }

}
