<div class="container" style="text-align: left;">
    <div>
        <input type="radio" name="name" (click)="searchType('name')">search by name 
        <input type="radio" name="name" (click)="searchType('date')" checked>search by date
    </div>
    <br>
    <div *ngIf="incorectFilter" class="alert alert-danger" role="alert">
        You have entered incorect search field!
      </div>
    <form [formGroup]="reportData">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Carton Barcode</label>
                    <input type="text" class="form-control" formControlName="cartonName"  [readonly]="typeSearch == 'date'">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Pouch Barcode</label>
                    <input type="text" class="form-control" formControlName="pouchName"  [readonly]="typeSearch == 'date'">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Group Number</label>
                    <input type="text" class="form-control" formControlName="groupName"  [readonly]="typeSearch == 'date'">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>From Date</label>
                    <input type="date" class="form-control" formControlName="from"  [readonly]="typeSearch == 'name'">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>To Date</label>
                    <input type="date" class="form-control" formControlName="to"  [readonly]="typeSearch == 'name'">
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary" (click)="filter()">View</button>
        <span>Count: {{reports.length}}</span>
        <button class="btn btn-primary" style="float: right;" (click)="exportexcel()">Export Excel</button>
    </form>
</div>

<br><br>
<div style="max-width: 100%; overflow: auto; font-size: 10px; height: 700px;">
    <table class="table" id="excel-table">
        <tr>
            <td>Lone A/C</td>
            <td>Product</td>
            <td>BRANCH NO</td>
            <td>Meeting Center ID</td>
            <td>GR NO</td>
            <td>Sub group ID</td>
            <td>ClientId</td>
            <td>NAME</td>
            <td>Disbursement Date</td>
            <td>Months</td>
            <td>UCG</td>
            <td>DPN</td>
            <td>Appli. Form</td>
            <td>CLI Form</td>
            <td>KYC Copy</td>
            <td>New request form</td>
            <td>FILE BARCODE</td>
            <td>CARTON NO</td>
            <td>POD Number</td>
            <td>Received Date</td>
            <td>Inward Date</td>
        </tr>
        <tr *ngFor="let r of reports;">
            <td>{{r.loanAccount}}</td>
            <td>{{r.product}}</td>
            <td>{{r.branchNo}}</td>
            <td>{{r.meetingCenterId}}</td>
            <td>{{r.groupName}}</td>
            <td>{{r.subgroupID}}</td>
            <td>{{r.clientID}}</td>
            <td>{{r.name}}</td>
            <td>{{r.disbursementDate}}</td>
            <td>{{r.month}}</td>
            <td>{{r.documentStatusUCG}}</td>
            <td>{{r.documentStatusDPN}}</td>
            <td>{{r.documentStatusApplicationForm}}</td>
            <td>{{r.documentStatusCLIForm}}</td>
            <td>{{r.documentStatusKYCCopy}}</td>
            <td>{{r.documentStatusNewRequestForm}}</td>
            <td>{{r.filebarcode}}</td>
            <td>{{r.cartonno}}</td>
            <td>{{r.podNumber}}</td>
            <td>{{r.receivedDate}}</td>
            <td>{{r.inwardDate}}</td>
        </tr>
    </table>
</div>

<br><br><br><br><br><br><br><br><br><br><br><br><br><br>