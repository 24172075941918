<mat-toolbar color="primary" style="background-color: #000000;">
  <div fxShow="true" *ngIf="authService.isLoggedIn();" (click)="check()">
    <button mat-icon-button style="color: white;" class="btn" (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <a mat-button class="companyName" routerLink="/">
    <span>
      <img src="./assets/mls.png" height="50px" width="100px">
    </span>
  </a>
  <span class="example-spacer">
  </span>
  <div fxShow="true">
    <app-navbar></app-navbar>
  </div>
</mat-toolbar>

<mat-sidenav-container fxFlexFill class="example-container">
  <mat-sidenav color="primary" class="side-nav" #sidenav fxLayout="column" mode="over" opened="false">
    <div fxLayout="column">
      <span>
        <div style="background-color: #000000;">
          <!-- <span>
            <a class="submenu" routerLink="/data-set" (click)="sidenav.toggle()" routerLinkActive="active" ngbDropdownItem>Data Entry</a>
          </span> -->
          <!-- <span>
            <a class="submenu" routerLink="/data-set-admin" (click)="sidenav.toggle()" routerLinkActive="active" ngbDropdownItem>Data Entry For Admin</a>
          </span> -->
          <!-- <span>
            <a class="submenu" routerLink="/pod-master" (click)="sidenav.toggle()" routerLinkActive="active" ngbDropdownItem>Pod Master</a>
          </span> -->
          <!-- <span>
            <a class="submenu" routerLink="/pod-list" (click)="sidenav.toggle()" routerLinkActive="active" ngbDropdownItem>Pod Master</a>
          </span> -->
          <!-- <span>
              <a class="submenu" routerLink="/role-list" (click)="sidenav.toggle()" routerLinkActive="active"  ngbDropdownItem>Role Master</a>
            </span>
            <span *ngIf="isAdmin">
              <a class="submenu" routerLink="/user-list" (click)="sidenav.toggle()" routerLinkActive="active"  ngbDropdownItem>User Master</a>
            </span>
            <span>
              <a class="submenu" routerLink="/clientupload" (click)="sidenav.toggle()" routerLinkActive="active"  ngbDropdownItem>Excel Upload</a>
            </span>
            <span>
              <a class="submenu" routerLink="/reports" (click)="sidenav.toggle()" routerLinkActive="active"  ngbDropdownItem>Reports</a>
            </span>
            <span>
              <a class="submenu" routerLink="/mis-reports" (click)="sidenav.toggle()" routerLinkActive="active"  ngbDropdownItem>MIS Reports</a>
            </span> -->
          <span *ngFor="let menu of menuList">
            <div>
              <span>
                <a class="submenu" routerLink="/{{menu.link}}" (click)="sidenav.toggle()" routerLinkActive="active" ngbDropdownItem>{{menu.name}}</a>
              </span>
            </div>
          </span>
        </div>
      </span>
    </div>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill style="background-color: rgb(243, 237, 243);">
    <br>

    <router-outlet></router-outlet>


    <section style="position: relative; top: 0;">
      <div style="height: 120px; background-color: #000000; color: beige;padding-top: 20px;">
        Copyright @2021. Macron Logi. All rights reserved.
      </div>
    </section>
  </mat-sidenav-content>

</mat-sidenav-container>
