import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserMasterService } from '../user-master.service';
import { Location } from '@angular/common';
import { RoleMasterService } from 'src/app/role-master/role-master.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  roleList: any = [0]

  constructor(
    private loc: Location,
    private fb: FormBuilder,
    private userService: UserMasterService,
    private router: Router,
    private roleservice: RoleMasterService,
    private route: ActivatedRoute
  ) { }

  userData: FormGroup;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings : IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  public id = parseInt(this.route.snapshot.paramMap.get('id'));

  goBack() {
    this.loc.back();
  }

  ngOnInit(): void {
    this.roleservice.GetRoleDropdown().subscribe(result => {
      this.dropdownList = result.dropDown.map(
        data => {
          return {
            item_id : data.value,
            item_text : data.name,
          }
        }
      )
      // console.log(this.dropdownList);
    });

    this.userData= this.fb.group({
      FirstName: ['', Validators.required],
      MiddleName: [''],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required , Validators.email]],
      MobileNumber: ['', [Validators.required,Validators.minLength(10), Validators.maxLength(10),Validators.pattern(new RegExp("[0-9 ]{10}"))]],
      UserName: ['', Validators.required],
      listroles: [null, Validators.required],
      // PasswordHash: ["", Validators.required]
    })


    this.userService.getUser(this.id).subscribe(
      data => {
        // console.log(data.masterDetails.user)

        this.userData = this.fb.group({
          FirstName: [data.masterDetails.user.firstName, Validators.required],
          MiddleName: [data.masterDetails.user.middleName],
          LastName: [data.masterDetails.user.lastName, Validators.required],
          Email: [data.masterDetails.user.email, Validators.required],
          MobileNumber: [data.masterDetails.user.mobileNumber, Validators.required],
          UserName: [data.masterDetails.user.userName, Validators.required],
          listroles: [data.masterDetails.user.listroles, Validators.required]
          // PasswordHash: ["", Validators.required]
        })
        var list = this.userData.value.listroles

        var slist = []
        for(let l of list){
          for(let d of this.dropdownList){
            if(l === d.item_id){
              slist.push(d);
            }
          }
        }

        this.selectedItems = slist.map(
          data => {
            return data
          }
        )
      })

      // console.log(this.selectedItems);
  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  add_user(){
    var list = this.selectedItems.map(
      data => {
        return data.item_id;
      }
    )

    if(list.length <= 0){
      return false;
    }

    this.userData.value.listroles = list;

    if(!this.userData.valid){
      return false;
    }

    // console.log(this.userData);
    this.userService.userEdit(this.userData , this.id).subscribe(
      data => {
        // console.log(data)
        this.router.navigate(["user-list"])
      }
      ,error => {
        // console.log(error);
      }
    )


    // //console.log(this.userData)
    // if(!this.userData.valid){
    //   return false;
    // }
    // this.userService.userEdit(this.userData, this.id).subscribe(
    //   data => {
    //     //console.log(data)
    //     this.router.navigate(["user-list"])
    //   }
    // )
  }

}
