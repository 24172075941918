import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientuploadService } from '../service/clientupload.service';
import { MatCardModule } from '@angular/material/card';
import { Clientupload } from '../model/clientupload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { FileSizeExcidedComponent } from './file-size-excided.component';

@Component({
  selector: 'app-clientupload',
  templateUrl: './clientupload.component.html',
  styleUrls: ['./clientupload.component.css']
})
export class ClientuploadComponent implements OnInit {
  Allfiles: boolean = false;
  public progress = 0;
  public message = "";
  form: FormGroup;
  actionType: string;
  id: number = 0;
  IsEdit: boolean = false;
  @Output() public onUploadFinished = new EventEmitter();
  // myAppUrl: string;
  //formBuilder: any;
  save_called = false;

  extAccepts = "application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*";
  isCompleted: boolean = false;
  userId: string = null;
  myAppUrl = 'https://dataentryapi.mylocalo.com/';
  constructor(private http: HttpClient,
    private clientUploadService: ClientuploadService,
    private formBuilder: FormBuilder,
    private avRoute: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
    // this.myAppUrl =  environment.appUrl;
    if (localStorage.getItem("Allfiles") == "0")
      this.extAccepts = "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,";

    const idParam = 'id';
    this.actionType = 'Add';
    localStorage.setItem("Allfiles", "0");
    if (this.avRoute.snapshot.params[idParam]) {
      this.id = this.avRoute.snapshot.params[idParam];
    }

    // this.form = this.formBuilder.group({
    //   id: 0,
    //   filePath: ['', Validators.required],
    // })

  }


  ngOnInit() {
    this.IsEdit = localStorage.getItem("IsEdit") == null ? false : true;
    this.progress = 0;


    this.form = this.formBuilder.group({
      id: 0,
      filePath: ['', Validators.required],
    })
  }

  uploadedFiles: string[] = [];
  public response: { filePath: ''; } | any;
  public uploadFinished = (event: any) => {
    debugger;
    this.response = event;
    this.form.get('filePath')?.setValue(this.response?.filePath);
    //form.get('filePath').setValue(this.response.filePath);
    this.uploadedFiles.push(this.response.filePath);
  }

  save() {
    this.save_called = true;
    //debugger;
    this.isCompleted = true;
    //this.alertService.clear();
    let clientUpload: Clientupload = this.form.value;
    clientUpload.id = this.id;
    clientUpload.uploadedFiles = this.uploadedFiles;
    clientUpload.userId = localStorage.getItem("userId");

    //this.clientUploadService.getUploadFile()
    this.clientUploadService.uploadFile(clientUpload).subscribe((data: any) => {
      // alert('Records added successfully');
      if (data.masterHead.code === "1") {
        alert('Records added successfully');
      }
      else if (data.masterHead.code === "2") {
        //this.alertService.error('Error Occurred. Data is not updated. Please check excel file.', { keepAfterRouteChange: false });
        //alert('Error Occurred. Data is not updated. Please check excel file.');
        alert(data.masterHead.message);
      }
      else {
        //this.alertService.info('Following Cost centers are not present in system:<br>' + JSON.stringify(data.noCostCenterList).split(',').join("<br>"), { keepAfterRouteChange: false });
        alert(data.masterHead.message);
      }
      this.ngOnInit();
      setTimeout(() => {
        this.isCompleted = false;
        this.router.navigate(['/clientupload']);
      }, 100);
    },
      error => {
        //this.alertService.error(error);
      });

  }

  public uploadFile = (files: any) => {
    this.userId = localStorage.getItem("userId");
    if (files.length === 0) {
      return;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    if (files[0].size < 31457280) {
      this.sharedService.visible(true)
      for (var i = 0; i < files.length; i++) {
        let fileToUpload = <File>files[this.IsEdit ? 0 : i];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('userId', this.userId);

        this.http.post(this.myAppUrl + 'api/upload', formData,
          {
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': '*'
            }),
            observe: 'events',
            reportProgress: true
          }
        )
          .subscribe(event => {

            if (event.type === HttpEventType.UploadProgress)
              this.progress = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
              //this.message = 'Upload success.';
              this.uploadFinished(event.body);
            }

          });
      }
      this.save_called = false;
      this.sharedService.visible(false)
    }
    else{
      const dialogRefReg = this.dialog.open(FileSizeExcidedComponent);
    }
  }

  get f() { return this.form.controls; }
}
