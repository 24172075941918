import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from '../layout.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private layoutService: LayoutService
  ) { }
  isAdmin = false;
  menuList = [];


  ngOnInit(): void {
    this.layoutService.getMenuUpdatedListener().subscribe(
      menu => {
        if(this.menuList.length !== 0){
          this.menuList = [];
          this.removeDuplicates(menu);
        }
      }
    )
  }

  check(){
    if(localStorage.getItem("roleId") == "1"){
      this.isAdmin = true;
    }
    else{
      this.isAdmin = false;
    }

    if (localStorage.getItem('menu') != null) {
      var menu = CryptoJS.AES.decrypt(localStorage.getItem('menu'), 'secretKey').toString(CryptoJS.enc.Utf8)
      //console.log(JSON.parse(menu));
      this.layoutService.getMenuWhenRefresh(JSON.parse(menu))
      var menulist = JSON.parse(menu);
      if(this.menuList.length === 0){
        this.removeDuplicates(menulist);
      }
    }
  }

  removeDuplicates(menulist){
    var flag = true;
    for(var i=0 ; i< menulist.length ; i++){
      flag = true;
      for(var j=i+1 ; j< menulist.length ; j++){
        if(menulist[i].id === menulist[j].id){
          flag = false;
        }
      }
      if(flag){
        this.menuList.push(menulist[i]);
      }
    }
  }



}

