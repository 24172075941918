import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { PodService } from '../pod.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-mis-reports',
  templateUrl: './mis-reports.component.html',
  styleUrls: ['./mis-reports.component.scss']
})
export class MisReportsComponent implements OnInit {

  constructor(private fb: FormBuilder, private podService: PodService, private sharedService: SharedService) { }
  reportData: FormGroup
  reports = []
  ngOnInit(): void {
    this.reportData = this.fb.group({
      from: [new Date().toISOString().split("T")[0]],
      to: [new Date().toISOString().split("T")[0]],
    })
    this.sharedService.visible(true)
    this.podService.misreport(this.reportData).subscribe(
      data => {
        // console.log(data)
        this.sharedService.visible(false)
        this.reports = data.masterDetails.misReportList
      }
    )
  }

  fileName= 'misReport.xlsx';

exportexcel(){
       let element = document.getElementById('excel-table');
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       XLSX.writeFile(wb, this.fileName);
    }

  filter(){
    this.sharedService.visible(true)
    this.podService.misreport(this.reportData).subscribe(
      data => {
        // console.log(data)
        this.sharedService.visible(false)
        this.reports = data.masterDetails.misReportList
      }
    )
  }

}
