import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private spin = new Subject<any>();
  spinner = false;
  constructor() { }

  visible(s) {
    this.spinner = s;
    this.spin.next(this.spinner);
  }

  setSpinner(): Observable<any>{
    return this.spin.asObservable();
  }

}
