import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PodService } from '../pod.service';
import * as XLSX from 'xlsx';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(private podService: PodService, private fb: FormBuilder, private sharedService: SharedService) { }

  reportData: FormGroup;

  reports = []
  incorectFilter = false;
  typeSearch = 'date';

  ngOnInit(): void {

    this.reportData = this.fb.group({
      from: [new Date().toISOString().split("T")[0]],
      to: [new Date().toISOString().split("T")[0]],
      cartonName: [""],
      pouchName: [""],
      groupName: [""]
    })
    // this.sharedService.visible(true)
    // this.podService.filterData(this.reportData).subscribe(
    //   data => {
    //     this.sharedService.visible(false)
    //     data.masterDetails.listClientInfo.forEach(element => {
    //        let d = {
    //         loanAccount: element.loanAccount,
    //         product: element.product,
    //         branchNo: element.branchNo,
    //         meetingCenterId: element.meetingCenterId,
    //         groupName: element.groupNumber.groupName,
    //         subgroupID: element.subgroupID,
    //         clientID: element.clientID,
    //         name: element.name,
    //         disbursementDate: element.disbursementDate.split("T")[0],
    //         month: element.month,
    //         documentStatusUCG: element.documentStatusUCG.docStatus,
    //         documentStatusDPN: element.documentStatusDPN.docStatus,
    //         documentStatusApplicationForm: element.documentStatusApplicationForm.docStatus,
    //         documentStatusCLIForm: element.documentStatusCLIForm.docStatus,
    //         documentStatusKYCCopy: element.documentStatusKYCCopy.docStatus,
    //         documentStatusNewRequestForm: element.documentStatusNewRequestForm.docStatus,
    //         filebarcode: element.filebarcode,
    //         cartonno: element.cartonno,
    //         podNumber: element.podNumber,
    //         receivedDate: element.receivedDate.split("T")[0],
    //         inwardDate: element.inwardDate.split("T")[0]
    //        }
    //        this.reports.push(d)
    //     });
    //   }
    // )
  }

  searchType(event){
    this.typeSearch = event;
    if(event=="date"){
      this.reportData.patchValue({cartonName: null, pouchName: null, groupName: null})
    }
    else{
      this.reportData.patchValue({from: null, to: null})
    }
  }

  report_search_data(){
    this.reportData.patchValue({from: null, to: null})
  }

fileName= 'dataEntry.xlsx';  

exportexcel(){  
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       XLSX.writeFile(wb, this.fileName);
    }

  filter(){
    if((!this.reportData.get("cartonName").value && !this.reportData.get("pouchName").value && !this.reportData.get("groupName").value) && (!this.reportData.get("from").value || !this.reportData.get("to").value)){
      this.incorectFilter = true;
      return false;
    }
    this.incorectFilter = false;
    this.sharedService.visible(true)
    this.podService.filterData(this.reportData).subscribe(
      data => {
        this.sharedService.visible(false)
        this.reports = [];
        data.masterDetails.listClientInfo.forEach(element => {
           let d = {
            loanAccount: element.loanAccount,
            product: element.product,
            branchNo: element.branchNo,
            meetingCenterId: element.meetingCenterId,
            groupName: element.groupNumber.groupName,
            subgroupID: element.subgroupID,
            clientID: element.clientID,
            name: element.name,
            disbursementDate: element.disbursementDate.split("T")[0],
            month: element.month,
            documentStatusUCG: element.documentStatusUCG.docStatus,
            documentStatusDPN: element.documentStatusDPN.docStatus,
            documentStatusApplicationForm: element.documentStatusApplicationForm.docStatus,
            documentStatusCLIForm: element.documentStatusCLIForm.docStatus,
            documentStatusKYCCopy: element.documentStatusKYCCopy.docStatus,
            documentStatusNewRequestForm: element.documentStatusNewRequestForm.docStatus,
            filebarcode: element.filebarcode,
            cartonno: element.cartonno,
            podNumber: element.podNumber,
            receivedDate: element.receivedDate.split("T")[0],
            inwardDate: element.inwardDate.split("T")[0]
           }
           this.reports.push(d)
        });
      }
    )
  }

}
