import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private router: Router,
    public layoutService: LayoutService
  ) { }
  user = "";
  files = "0";


  ngOnInit(): void {
    this.layoutService.getCountUpdateListener().subscribe(
      data => {
        this.files = data;
      },
      error => {
        console.log(error)
      }
    )
    this.layoutService.getNameUpdateListener().subscribe(
      data => {
        this.user = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  getUser() {
    this.user = localStorage.getItem("user");
    this.files = localStorage.getItem("Filecount")
  }



}
