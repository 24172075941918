<div class="container" style="text-align: left;">
  <div class="user-box">
    Role Details
  </div>
  <br>
  <table>
    <tbody>
      <tr>
        <th>Role Name :- </th>
        <td>{{ roleName }}</td>
      </tr>
      <tr>
        <th>Status :- </th>
        <td>{{ roleStatus }}</td>
      </tr>
      <tr>
        <th>List of Menu :- </th>
        <td>
          <div *ngFor="let m of menuList;let i=index" class="inner-box">
            {{ i + 1 }}]<span style="margin-left: 5px;">{{ m.name }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <br>
  <button type="button" (click)="goBack()" class="btn btn-danger btn-back" style="margin-left: 8px;">
    <mat-icon style="position: relative; top: 5px;">first_page</mat-icon> Back
  </button>

</div>
