import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataSetComponent } from '../data-set/data-set.component';
import { PodService } from '../pod.service';

@Component({
  selector: 'app-file-edit',
  templateUrl: './file-edit.component.html',
  styleUrls: ['./file-edit.component.scss']
})
export class FileEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private podService: PodService,
    public dialogRef: MatDialogRef<DataSetComponent>
  ) {
    // console.log(data)
    this.fileData = this.fb.group({
      groupNumber: [this.data.fileData.groupNumber],
      clientID: [this.data.fileData.clientID],
      loanAccount: [this.data.fileData.loanAccount],
      name: [this.data.fileData.name],
      UCG: [this.data.fileData.ucg],
      DPN: [this.data.fileData.dpn],
      applicationForm: [this.data.fileData.applicationForm],
      CLIForm: [this.data.fileData.cliForm],
      KYCCopy: [this.data.fileData.kycCopy],
      newRequestForm: [this.data.fileData.newRequestForm],
    })
  }

  fileData: FormGroup;
  status = []

  ngOnInit(): void {


    this.podService.getStatusList().subscribe(
      data => {
        // console.log(data)
        this.status = data.masterDetails.listDocumentStatus
      }
    )
  }

  update(){
    if(!this.fileData.valid){
      return false;
    }
    this.podService.fileEdit(this.data.fileData.clientInfoId, this.fileData, this.data.podData).subscribe(
      data => {
        // console.log(data);
        this.dialogRef.close()
      }
    )

  }



}
