<div class="container" style="text-align: left;">
    <form [formGroup]="searchData">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="search">Search Group</label>
                    <input type="text" formControlName="search" class="form-control" id="search"
                        placeholder="Search Group">
                </div>
            </div>
            <div class="col-md-2 p-0">
                <br>
                <button class="btn btn btn-primary mt-2" style="float: left;" (click)="search_group()">
                    Search
                </button>
            </div>
        </div>
    </form>
</div>
<br>
<div style="width: 100%; overflow: auto;">
    <form [formGroup]="fileDataArray">
        <table class="table table-hover table-bordered" style="font-size: 12px;">
            <thead>
                <tr>
                    <th style="padding: 0;">Client ID</th>
                    <th style="padding: 0;">Loan Account</th>
                    <th style="padding: 0;">Product</th>
                    <th style="padding: 0;">Disbursement Date</th>
                    <th style="padding: 0;">Name</th>
                    <th style="padding: 0;">UCG</th>
                    <th style="padding: 0;">DPN</th>
                    <th style="padding: 0;">App Form</th>
                    <th style="padding: 0;">CLI Form</th>
                    <th style="padding: 0;">KYC</th>
                    <th style="padding: 0;">New Req Form</th>
                    <th style="padding: 0;">Action</th>
                </tr>
            </thead>
            <tbody formArrayName="fileData" *ngFor="let g of fileData.controls; let i=index;">
                <tr [formGroupName]="i">
                    <td>{{groupList[i].clientID}}</td>
                    <td>{{groupList[i].loanAccount}}</td>
                    <td>{{groupList[i].product}}</td>
                    <td>{{groupList[i].disbursementDate.split("T")[0]}}</td>
                    <td>{{groupList[i].name}}</td>
                    <td>
                        <select class="form-control" formControlName="UCG">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="DPN">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="applicationForm">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="CLIForm">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="KYCCopy">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="newRequestForm">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <mat-icon *ngIf="!groupList[i].edit"
                            style="color: blue; cursor: pointer;" (click)="act_edit(i)">edit</mat-icon>
                        <mat-icon *ngIf="groupList[i].edit"
                            style="color: blue; cursor: pointer;" (click)="edit_data(i)">done</mat-icon>
                    </td>
                </tr>
            </tbody>

        </table>
    </form>
</div>

<br><br><br><br><br><br><br><br>
