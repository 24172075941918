import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RoleMasterService {

  constructor(private http: HttpClient) { }
  link = environment.link;
  role = { listMenu: [] }
  getRoleList(): Observable<any> {
    let data = {
      "MASTERHEAD": {
        "UserId": 3
      },
      "MASTERDETAILS": {
      }
    }
    return this.http.post<any>(this.link + "api/Role/ListRole", data);
  }
  addRole(data): Observable<any> {
    let d = {
      "MASTERHEAD": {
        "UserId": localStorage.getItem("userId")
      },
      "MASTERDETAILS": {
        "Role": {
          "Name": data.get('name').value,
          "listMenu": []
        }
      }
    }
    for (let i = 0; i < data.get("selectedItems").value.length; i++) {
      d.MASTERDETAILS.Role.listMenu.push(data.get("selectedItems").value[i].item_id)
    }
    return this.http.post<any>(this.link + "api/Role/CreateRole", d);
  }
  editRole(id, data): Observable<any> {
    let d = {
      "MASTERHEAD": {
        "UserId": localStorage.getItem("userId")
      },
      "MASTERDETAILS": {
        "Role": {
          "Id": id,
          "Name": data.get('name').value,
          "listMenu": [],
          "Status": data.get('status').value
        }
      }
    }
    for (let i = 0; i < data.get("selectedItems").value.length; i++) {
      d.MASTERDETAILS.Role.listMenu.push(data.get("selectedItems").value[i].item_id)
    }
    return this.http.post<any>(this.link + "api/Role/UpdateRole", d);
  }
  getRole(id) {
    let d = {
      "MASTERHEAD": {
        "UserId": 1,
        "Id": id
      },
      "MASTERDETAILS": {
      }
    }
    return this.http.post<any>(this.link + "api/Role/GetData", d);
  }

  deleteRole(id) {
    var d = {
      "MASTERHEAD": {
        "Id": id
      },
      "MASTERDETAILS": {
      }
    }
    // console.log(d);
    return this.http.post<any>(this.link + "api/Role/DeleteRole", d)

  }


  GetRoleDropdown() {
    return this.http.post<any>(this.link + "api/Role/GetDropDownList", null)

  }
  GetMenuDropdown() {
    return this.http.post<any>(this.link + "api/Menu/ListMenu", { "MASTERHEAD": {} })

  }

  getRoleDetails(d) {
    // console.log(d)
    this.role = d
  }
}
