import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared.service';


@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  spin = false;

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.setSpinner().subscribe(
      data => {
        this.spin = data;
      });
  }



}
