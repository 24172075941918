import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleAddComponent } from './role-add/role-add.component';
import { RoleEditComponent } from './role-edit/role-edit.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RoleListComponent } from './role-list/role-list.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RoleDeleteComponent } from './role-delete/role-delete.component';
import { RoleDeleteSuccessComponent } from './role-delete/role-delete-success.component';
import { RoleDetailsComponent } from './role-details/role-details.component';


@NgModule({
  declarations: [
    RoleAddComponent,
    RoleEditComponent,
    RoleListComponent,
    RoleDeleteComponent,
    RoleDeleteSuccessComponent,
    RoleDetailsComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class RoleMasterModule { }
