<div class="container">
  <div class="user-box">
    Add POD Number
  </div>
  <div class="row alert alert-danger" *ngIf="showError" style = "margin:10px">
    {{ showError }}
  </div>
  <form [formGroup]="podData" (ngSubmit)="add_pod_number()">
    <br>
    <div class="row" style="text-align: left;">
      <div class="col-md-4">
        <div class="form-group" style="margin-top: 13px;">
          <label>POD Number</label>
          <input class="form-control" formControlName="name" placeholder="Enter POD number..." required>
        </div>
      </div>
    </div>
    <br>
    <!-- <div class="my-auto" style="text-align: left;">
      <label class="switch">
        <input type="checkbox" formControlName="status">
        <span class="slider round my-auto"></span>
      </label> &nbsp; Status<br><br><br>
    </div> -->
    <div style="text-align: left;">
      <button type="submit" class="btn btn-save" [disabled]="!podData.valid">
        <mat-icon style="font-size: 14px; font-weight: bold;">add</mat-icon>Save
      </button>
      <button type="button" (click)="goBack()" class="btn btn-danger btn-back" style="margin-left: 8px;">
        <mat-icon style="position: relative; top: 5px;">first_page</mat-icon> Back
      </button>
    </div>
  </form>
</div>
<br><br><br>
<br><br><br>
<br><br><br><br><br><br>
