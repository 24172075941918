import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
// import { MatDialogRef } from '@angular/material';

import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
//import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule} from '@angular/material/stepper';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ClientuploadComponent } from './client-upload/client-upload/clientupload.component';
//import { MatSpinner} from '@angular/material/progress-spinner'
//import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatRadioModule,
    //NgMaterialMultilevelMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatDatepickerModule,
    //MatMomentDateModule,
    MatNativeDateModule,
    MatGridListModule,
    CdkTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatProgressBarModule,
   // MatSpinner
  ],
  exports: [
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatRadioModule,
    //NgMaterialMultilevelMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatDatepickerModule,
    //MatMomentDateModule,
    MatNativeDateModule,
    MatGridListModule,
    CdkTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatProgressBarModule,
   //// MatSpinner
  ],
  declarations:[
    // ClientuploadComponent,

  ]
})


export class MaterialModule {}
