import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/shared.service';
import { BoxAddComponent } from '../box-add/box-add.component';
import { SidebarComponent } from 'src/app/layout/sidebar/sidebar.component';
import { DataDialogComponent } from '../data-dialog/data-dialog.component';
import { FileEditComponent } from '../file-edit/file-edit.component';
import { PodService } from '../pod.service';
import { PouchAddComponent } from '../pouch-add/pouch-add.component';
import { CheckAllEditComponent } from '../data-dialog/check-all-edit.component';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-data-set',
  templateUrl: './data-set.component.html',
  styleUrls: ['./data-set.component.scss'],
  providers: [DatePipe]
})
export class DataSetComponent implements OnInit {

  constructor(
    private podService: PodService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private sidebarcomponent: SidebarComponent,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) { }

  groupList = [];
  pouchCounter = 0;
  boxCounter = 0;
  check = 0;
  podNumberList = [];
  receivedDate :any;

  searchData: FormGroup;
  podData: FormGroup;
  status = [];
  fileDataArray: FormGroup;

  ngOnInit(): void {

    this.sharedService.visible(true);
    this.searchData = this.fb.group({
      search: ["", Validators.required]
    })
    this.podData = this.fb.group({
      podNumber: ["", [Validators.required]],
      receivedDate: ["", Validators.required]
    })
    this.fileDataArray = this.fb.group({
      fileData: this.fb.array([])
    })


    this.podService.getPouchs().subscribe(
      data => {
        this.sharedService.visible(false);
        if (data.masterDetails.groupNumber) {
          this.pouchCounter = data.masterDetails.groupNumber.pouchCounter
        }
      }
    )
    this.podService.getboxes().subscribe(
      data => {
        if (data.masterDetails.pouch) {
          this.boxCounter = data.masterDetails.pouch.boxCounter
        }
      }
    )
    this.podService.getStatusList().subscribe(
      data => {
        this.status = data.masterDetails.listDocumentStatus
      }
    )
    this.podService.getPODDropDownList().subscribe(
      data => {
        // console.log(data.masterDetails.listPOD);
        this.podNumberList = data.masterDetails.listPOD;
      },
      error => {
        // console.log(error);
      }
    )


  }

  search_group() {
    if (!this.searchData.valid) {
      return false;
    }
    this.sharedService.visible(true);
    this.podService.getRecords(this.searchData).subscribe(
      data => {
        // console.log(data);
        this.groupList = data.masterDetails.listClientInfo
        this.createFormArray();
        this.sharedService.visible(false);
        this.checkAll();
      }
    )
  }

  edit_data(i) {
    if (!this.podData.valid) {
      const dialogRef = this.dialog.open(DataDialogComponent);
      return false;
    }
    let f = this.fileDataArray.get('fileData') as FormArray;
    // this.sharedService.visible(true);
    this.podService.fileEdit(this.groupList[i].clientInfoId, f.at(i), this.podData).subscribe(
      data => {
        // this.podService.getRecords(this.searchData).subscribe(
        //   data => {
        //     console.log(data)
        //     this.groupList = data.masterDetails.listClientInfo
        //     this.sharedService.visible(false);
        //   }
        // )
        this.podService.getPouchs().subscribe(
          data => {
            if (data.masterDetails.groupNumber) {
              this.pouchCounter = data.masterDetails.groupNumber.pouchCounter
            }
          }
        )
        this.podService.getboxes().subscribe(
          data => {
            if (data.masterDetails.pouch) {
              this.boxCounter = data.masterDetails.pouch.boxCounter
            }
          }
        )
        // f.at(i).get("UCG").disable();
        // f.at(i).get("DPN").disable();
        // f.at(i).get("applicationForm").disable();
        // f.at(i).get("CLIForm").disable();
        // f.at(i).get("KYCCopy").disable();
        // f.at(i).get("newRequestForm").disable();
        this.groupList[i].edit = false;
      }
    )


    // console.log(this.podData)
    // if(!this.podData.valid){
    //   const dialogRef = this.dialog.open(DataDialogComponent);
    //   return false;
    // }
    // console.log(file)
    // const dialogRef = this.dialog.open(FileEditComponent, { data: {fileData: file, podData: this.podData} });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(result);

    //     this.sharedService.visible(true);
    //     this.podService.getRecords(this.searchData).subscribe(
    //       data => {
    //         console.log(data)
    //         this.groupList = data.masterDetails.listClientInfo
    //         this.sharedService.visible(false);
    //       }
    //     )
    //     this.podService.getPouchs().subscribe(
    //       data => {
    //         console.log(data)
    //         if(data.masterDetails.groupNumber){
    //           this.pouchCounter = data.masterDetails.groupNumber.pouchCounter
    //         }
    //       }
    //     )
    //     this.podService.getboxes().subscribe(
    //       data => {
    //         console.log(data)
    //         if(data.masterDetails.pouch){
    //           this.boxCounter = data.masterDetails.pouch.boxCounter
    //         }
    //       }
    //     )

    // });
  }

  edit(index, category) {
    let f = this.fileDataArray.get('fileData') as FormArray;
    if (category == 'UCG') {
      let t = f.at(index).get("UCG").value
      for (let i = 0; i < this.groupList.length; i++) {
        if (!this.groupList[i].userId) {
          f.at(i).patchValue({ UCG: t })
        }
      }
    }
    if (category == 'DPN') {
      let t = f.at(index).get("DPN").value
      for (let i = 0; i < this.groupList.length; i++) {
        if (!this.groupList[i].userId) {
          f.at(i).patchValue({ DPN: t })
        }

      }
    }
    if (category == 'newRequestForm') {
      let t = f.at(index).get("newRequestForm").value
      if (this.groupList[index].product == "MTRP") {
        for (let i = 0; i < this.groupList.length; i++) {
          if (this.groupList[index].product == this.groupList[i].product && !this.groupList[i].userId) {
            f.at(i).patchValue({ newRequestForm: t })
          }
        }
      }

    }
  }

  act_edit(i) {
    // let f = this.fileDataArray.get('fileData') as FormArray;
    // f.at(i).get("UCG").enable();
    // f.at(i).get("DPN").enable();
    // f.at(i).get("applicationForm").enable();
    // f.at(i).get("CLIForm").enable();
    // f.at(i).get("KYCCopy").enable();
    // f.at(i).get("newRequestForm").enable();
    this.groupList[i].edit = true;
  }

  add_pouch() {
    const dialogRef = this.dialog.open(PouchAddComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.sharedService.visible(true);
      this.podService.getRecords(this.searchData).subscribe(
        data => {
          this.groupList = data.masterDetails.listClientInfo
          this.sharedService.visible(false);
        }
      )
      this.podService.getPouchs().subscribe(
        data => {
          if (data.masterDetails.groupNumber) {
            this.pouchCounter = data.masterDetails.groupNumber.pouchCounter
          }
          else {
            this.pouchCounter = 0;
          }
        }
      )
      this.podService.getboxes().subscribe(
        data => {
          if (data.masterDetails.pouch) {
            this.boxCounter = data.masterDetails.pouch.boxCounter
          }
          else {
            this.boxCounter = 0;
          }
        }
      )
    });
  }

  add_box() {
    const dialogRef = this.dialog.open(BoxAddComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.sharedService.visible(true);
      this.podService.getRecords(this.searchData).subscribe(
        data => {
          this.groupList = data.masterDetails.listClientInfo
          this.sharedService.visible(false);
        }
      )
      this.podService.getPouchs().subscribe(
        data => {
          if (data.masterDetails.groupNumber) {
            this.pouchCounter = data.masterDetails.groupNumber.pouchCounter
          }
          else {
            this.pouchCounter = 0;
          }
        }
      )
      this.podService.getboxes().subscribe(
        data => {
          if (data.masterDetails.pouch) {
            this.boxCounter = data.masterDetails.pouch.boxCounter
          }
          else {
            this.boxCounter = 0;
          }
        }
      )
    });

  }

  check_status(id) {
    for (let i = 0; i < this.status.length; i++) {
      if (this.status[i].documentStatusId == id) {
        return this.status[i].docStatus
      }
    }
  }

  checkAllEditDone() {
    for (let i = 0; i < this.groupList.length; i++) {
      if (this.groupList[i].edit)
        return false;
    }
    return true;
  }

  createForm() {
    let d = {
      "masterHead": {
        "userId": localStorage.getItem("userId")
      }, "masterDetails": {
        "groupNumber": {
          "groupName": this.searchData.get("search").value,
          "PODNumber": this.podData.get("podNumber").value,
          "receivedDate": this.podData.get("receivedDate").value,
          "ClientInfoList": []
        }
      }
    }
    let f = this.fileDataArray.get('fileData') as FormArray;
    for (let i = 0; i < this.groupList.length; i++) {
      if (!this.groupList[i].userId) {
        let t = {
          "ClientID": this.groupList[i].clientID,
          "UCG": f.at(i).get("UCG").value,
          "DPN": f.at(i).get("DPN").value,
          "ApplicationForm": f.at(i).get("applicationForm").value,
          "CLIForm": f.at(i).get("CLIForm").value,
          "KYCCopy": f.at(i).get("KYCCopy").value,
          "NewRequestForm": f.at(i).get("newRequestForm").value
        }
        d.masterDetails.groupNumber.ClientInfoList.push(t)
      }
    }
    return d;
  }

  changePODNumber(event){
    // console.log(event.target.value);
    let index = this.podNumberList.find(p => p.podNumber === event.target.value);
    var receivedDate = new Date(index.receivedDate);
    this.receivedDate = this.datePipe.transform(receivedDate, 'yyyy-MM-dd');
    this.podData.controls.receivedDate.setValue(receivedDate)
  }

  allClientChecked() {
    if (!this.searchData.valid || !this.podData.valid) {
      const dialogRef = this.dialog.open(DataDialogComponent);
      return false;
    }
    if(!this.checkAllEditDone()){
      const dialogRef = this.dialog.open(CheckAllEditComponent);
      return false;
    }
    this.sharedService.visible(true);
    this.podService.groupCheck(this.createForm()).subscribe(
      data => {
        // console.log(data)
        this.sharedService.visible(false);
        this.searchData.patchValue({ search: "" })
        this.groupList = []
        this.fileDataArray = this.fb.group({
          fileData: this.fb.array([])
        })

        this.podService.getPouchs().subscribe(
          data => {
            if (data.masterDetails.groupNumber) {
              this.pouchCounter = data.masterDetails.groupNumber.pouchCounter
            }
            else {
              this.pouchCounter = 0;
            }
          }
        )
        this.podService.getboxes().subscribe(
          data => {
            if (data.masterDetails.pouch) {
              this.boxCounter = data.masterDetails.pouch.boxCounter
            }
            else {
              this.boxCounter = 0;
            }
          }
        )
      }
    )
  }

  checkAll() {
    this.check = 0;
    for (let i = 0; i < this.groupList.length; i++) {
      if (this.groupList[i].userId) {
        this.check = this.check + 1;
      }
    }
  }

  countGroup() {
    return this.groupList.length;
  }

  get fileData() {
    return this.fileDataArray.get('fileData') as FormArray;
  }

  addFileData(data) {
    this.fileData.push(this.fb.group({ UCG: data.ucg, DPN: data.dpn, applicationForm: data.applicationForm, CLIForm: data.cliForm, KYCCopy: data.kycCopy, newRequestForm: data.newRequestForm }));
  }

  createFormArray() {
    this.fileDataArray = this.fb.group({
      fileData: this.fb.array([])
    })
    for (let i = 0; i < this.groupList.length; i++) {
      this.addFileData(this.groupList[i]);
    }
  }


}
