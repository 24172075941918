import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { UserMasterService } from '../user-master.service';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  constructor(
    private router: Router,
    private userServices: UserMasterService,
    private loc: Location,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) { }

  displayedColumns: string[] = ['userName', 'email', 'mobileNumber', 'name', 'filecount','Active/inActive', 'Action'];

  dataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  user_edit(id, data) {
    //this.userServices.userDetails(data)
    this.router.navigate(['/user-add', id]);
  }


  goBack() {
    this.loc.back();
  }

  ngOnInit(): void {
    this.sharedService.visible(true);
    this.userServices.getUserList().subscribe(
      data =>{
        // console.log(data)
        this.dataSource = new MatTableDataSource(data.masterDetails?.listUsers);
        this.ngAfterViewInit();

        // console.log(data.masterDetails);
        if (this.displayedColumns.length === 0){

          this.displayedColumns = Object.keys(data[0]);
        }
        this.sharedService.visible(false);
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 403) {
            this.router.navigate(['/login']);
          }
          if (error.status === 500) {
            this.router.navigate(['/login']);
          }
        }
        this.sharedService.visible(false);
      });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
