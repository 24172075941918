import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';
import { PodNumberMasterService } from '../pod-number-master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pod-number-add',
  templateUrl: './pod-number-add.component.html',
  styleUrls: ['./pod-number-add.component.scss']
})
export class PodNumberAddComponent implements OnInit {

  constructor(
    private loc: Location,
    private fb : FormBuilder,
    private sharedService: SharedService,
    private podService: PodNumberMasterService,
    private router: Router
  ) { }

  podData: FormGroup;
  showError: any;

  goBack(){
    this.loc.back();
  }

  ngOnInit(): void {
    this.podData= this.fb.group({
      name: ['', Validators.required],
      //status: [true],
    })
  }

  add_pod_number(){
    // console.log(this.podData);
    this.sharedService.visible(true);
    this.podService.createPodNumber(this.podData.value.name).subscribe(
      data => {
        if(data.message === 'Success'){
          this.router.navigate(['/pod-list']);
        }else{
          this.showError = data.message;
        }
        this.sharedService.visible(false);
      },
      error => {
        // console.log(error);
        this.sharedService.visible(false);
      }
    )


  }

}
