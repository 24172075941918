import { HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private injector: Injector) { }
  intercept(req, next){
    let authService = this.injector.get(AuthService);
    let token = req.clone({
      setHeaders: {
        //Authorization:  authService.getToken(),
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
    return next.handle(token);
  }

}
