import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { RoleDeleteSuccessComponent } from '../role-delete/role-delete-success.component';
import { RoleDeleteComponent } from '../role-delete/role-delete.component';
import { RoleMasterService } from '../role-master.service';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private roleService: RoleMasterService,
    private sharedService: SharedService
    ) { }
  displayedColumns: string[] = ['name', 'status', 'action'];

  dataSource = new MatTableDataSource([]);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  role_edit(id, d) {
    this.roleService.getRoleDetails(d)
    this.router.navigate(['/role-add', id]);
  }

  role_display(id) {
    this.router.navigate(['/role-details', id]);
  }

  role_delete(id) {
    const dialogRef = this.dialog.open(RoleDeleteComponent, { data: { id: id } });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.sharedService.visible(true);
        this.roleService.deleteRole(result).subscribe(
            data => {
              // console.log(data);
              this.sharedService.visible(false);
            },
            error => {
              // console.log(error)
              this.sharedService.visible(false);
            }
          )

          const successDelete = this.dialog.open(RoleDeleteSuccessComponent)
          successDelete.afterClosed().subscribe(
            result => {
              this.sharedService.visible(true);
              this.roleService.getRoleList().subscribe(
                  data => {
                    this.dataSource = new MatTableDataSource(data.masterDetails.listRole);
                    this.ngAfterViewInit();
                    // console.log(data.masterDetails.roleList);

                    if (this.displayedColumns.length === 0) {
                      this.displayedColumns = Object.keys(data[0]);
                    }
                    this.sharedService.visible(false);
                  },
                  error => {
                    if (error instanceof HttpErrorResponse) {
                      if (error.status === 403) {
                        this.router.navigate(['/login']);
                      }
                      if (error.status === 500) {
                        this.router.navigate(['/login']);
                      }
                    }
                    this.sharedService.visible(false);
                  });

            }
          )
      }
    });
  }

  ngOnInit(): void {
    this.sharedService.visible(true);
    this.roleService.getRoleList().subscribe(
        data => {
          this.dataSource = new MatTableDataSource(data.masterDetails.listRole);
          this.ngAfterViewInit()
          this.sharedService.visible(false);
        },
        error => {
          // console.log(error);
          if (error instanceof HttpErrorResponse) {
            if (error.status === 403) {
              this.router.navigate(['/login']);
            }
            if (error.status === 500) {
              this.router.navigate(['/login']);
            }
          }
          this.sharedService.visible(false);
        }
      )
  }


}
