<br><br>

<div class="container" style="text-align: left;">
    <div class="row">
        <div class="col-md-8">
            <form [formGroup]="podData">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="pod">POD Number</label>
                            <!-- <input type="text" formControlName="podNumber" class="form-control" id="pod"
                                placeholder="POD Number"> -->
                            <select class="form-control" formControlName="podNumber" (change)="changePODNumber($event)">
                              <option disabled selected value="">Select</option>
                              <option *ngFor="let podno of podNumberList" [value]="podno.podNumber">{{ podno.podNumber }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="r_date">Received Date</label>
                            <input type="date" [(ngModel)]="receivedDate" [ngModelOptions]="{standalone: true}" class="form-control" id="r_date"
                                placeholder="Received Date" disabled>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-4">
            <br>
            <div class="row">
                <div class="col-6">Entries Ready for Pouch</div>
                <div class="col-1">{{ pouchCounter }}</div>
                <div class="col-5"><button class="btn btn-sm btn-primary" (click)="add_pouch()">Add Pouch</button></div>
            </div>
            <br>
            <div class="row">
                <div class="col-6">Pouches Ready for Box</div>
                <div class="col-1">{{boxCounter}}</div>
                <div class="col-5"><button class="btn btn-sm btn-primary" (click)="add_box()">Add Box</button></div>
            </div>
        </div>
    </div>
</div>
<br><br>
<div class="container" style="text-align: left;">
    <form [formGroup]="searchData">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="search">Search Group</label>
                    <input type="text" formControlName="search" class="form-control" id="search"
                        placeholder="Search Group">
                </div>
            </div>
            <div class="col-md-2 p-0">
                <br>
                <button class="btn btn btn-primary mt-2" style="float: left;" (click)="search_group()">
                    Search
                </button>
            </div>
            <div class="col-md-6">
                <br>
                <button class="btn btn btn-primary mt-2" *ngIf="check==0 || check != countGroup()" style="float: right;"
                    (click)="allClientChecked()">
                    All Client Checked
                </button>
            </div>
        </div>
    </form>

</div>
<br>
<div style="width: 100%; overflow: auto;">
    <form [formGroup]="fileDataArray">
        <table class="table table-hover table-bordered" style="font-size: 12px;">
            <thead>
                <tr>
                    <th style="padding: 0;">Client ID</th>
                    <th style="padding: 0;">Loan Account</th>
                    <th style="padding: 0;">Product</th>
                    <th style="padding: 0;">Disbursement Date</th>
                    <th style="padding: 0;">Name</th>
                    <th style="padding: 0;">UCG</th>
                    <th style="padding: 0;">DPN</th>
                    <th style="padding: 0;">App Form</th>
                    <th style="padding: 0;">CLI Form</th>
                    <th style="padding: 0;">KYC</th>
                    <th style="padding: 0;">New Req Form</th>
                    <!-- <th style="padding: 0;">Action</th> -->
                </tr>
            </thead>
            <tbody formArrayName="fileData" *ngFor="let g of fileData.controls; let i=index;">
                <tr [formGroupName]="i" [ngClass]="{'checked': groupList[i].userId}">
                    <td>{{groupList[i].clientID}}</td>
                    <td>{{groupList[i].loanAccount}}</td>
                    <td>{{groupList[i].product}}</td>
                    <td>{{groupList[i].disbursementDate.split("T")[0]}}</td>
                    <td>{{groupList[i].name}}</td>
                    <td>
                        <select class="form-control" formControlName="UCG" (change)="edit(i, 'UCG')" [attr.disabled]="groupList[i].userId">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="DPN" (change)="edit(i, 'DPN')" [attr.disabled]="groupList[i].userId">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="applicationForm" (change)="edit(i, 'applicationForm')" [attr.disabled]="groupList[i].userId">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="CLIForm" (change)="act_edit(i, 'CLIForm')" [attr.disabled]="groupList[i].userId">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="KYCCopy" (change)="edit(i, 'KYCCopy')" [attr.disabled]="groupList[i].userId">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" formControlName="newRequestForm" (change)="edit(i, 'newRequestForm')" [attr.disabled]="groupList[i].userId">
                            <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                        </select>
                    </td>
                    <!-- <td> -->
                        <!-- <mat-icon *ngIf="!groupList[i].userId && groupList[i].readonly"
                            style="color: blue; cursor: pointer;" (click)="act_edit(i)">edit</mat-icon> -->
                        <!-- <mat-icon *ngIf="!groupList[i].userId && groupList[i].edit"
                            style="color: blue; cursor: pointer;" (click)="edit_data(i)">done</mat-icon>
                    </td> -->
                </tr>
            </tbody>

        </table>
    </form>
</div>

<br><br><br><br><br><br><br><br>
