import { Location } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { RoleMasterService } from '../role-master.service';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent implements OnInit {

  constructor(
    private loc: Location,
    private route: ActivatedRoute,
    private roleService: RoleMasterService,
    private sharedService: SharedService
  ) { }


  goBack(){
    this.loc.back();
  }

  public id = parseInt(this.route.snapshot.paramMap.get('id'));
  menuList = [];
  roleName = null;
  roleStatus = null;


  ngOnInit(): void {
    this.sharedService.visible(true);
    this.roleService.getRoleList().subscribe(
      data => {
        var roleData = data.masterDetails.listRole;
        var listofmenu = [];
        for(let rd of roleData){
          if(rd.id === this.id){
            this.roleName = rd.name;
            this.roleStatus = rd.status;
            listofmenu = rd.listMenu;
          }
        }
        this.roleService.GetMenuDropdown().subscribe(
          data => {
            var mainMenuList = data.masterDetails.listMenu;
            for(let m of mainMenuList){
              for(let n of listofmenu){
                if(m.id === n){
                  this.menuList.push({id:m.id , name: m.name});
                }
              }
            }
            this.sharedService.visible(false);
          }
        ),
        error => {
          // console.log(error);
          this.sharedService.visible(false);
        }
      },
      error => {
        // console.log(error);
        this.sharedService.visible(false);
      }
    )
  }
}
