import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor() {
    if(this.name==""){
      this.setName(localStorage.getItem("user"));
      this.setCount(+localStorage.getItem("count"));
    }
   }
  private count = 0;
  private countUpdate = new Subject<any>();
  private name = "";
  private nameUpdate = new Subject<any>();
  private menuList = [];
  private menuUpdate = new Subject<any>();


  setCount(count){
    this.count = count;
    this.countUpdate.next(this.count);
  }

  getCountUpdateListener(): Observable<any>{
    return this.countUpdate.asObservable();
  }

  setName(n){
    this.name = n;
    this.nameUpdate.next(this.name);
  }

  getNameUpdateListener(): Observable<any>{
    return this.nameUpdate.asObservable();
  }

  setMenu(menu){
    this.menuList = menu;
    localStorage.setItem("menu" , CryptoJS.AES.encrypt(JSON.stringify(menu), 'secretKey'))
    this.menuUpdate.next([...this.menuList]);
  }

  getMenuUpdatedListener(): Observable<any>{
    return this.menuUpdate.asObservable();
  }

  getMenuWhenRefresh(menu){
    if(this.menuList.length === 0){
        this.menuList = menu;
        this.menuUpdate.next([...this.menuList]);
    }
  }

}
