import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { PodNumberMasterService } from '../pod-number-master.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-pod-number-details',
  templateUrl: './pod-number-details.component.html',
  styleUrls: ['./pod-number-details.component.scss']
})
export class PodNumberDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private podService: PodNumberMasterService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private loc: Location
  ) { }


  goBack(){
    this.loc.back();
  }


  public id = parseInt(this.route.snapshot.paramMap.get('id'));
  podNumber : any ;
  podStatus : any ;

  ngOnInit(): void {
    this.sharedService.visible(true);
    this.podService.getPodNumberList().subscribe(
      data => {
        var listdata = data.masterDetails.listPOD;
        var index = listdata.find(l => l.id === this.id);
        if(index === undefined){
          this.router.navigate(['/pod-number-list']);
        }
        // console.log(index);
        this.podNumber = index.podNumber;
        if(index.status){
          this.podStatus = 'Active';
        }else{
          this.podStatus = 'Inactive';
        }
        this.sharedService.visible(false);
      },
      error => {
        // console.log(error);
        this.sharedService.visible(false);
      }
    )
  }

}
