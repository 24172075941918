import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';
import { SharedService } from 'src/app/shared/shared.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {
  errorFlag = false;
  hide = true;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private layoutService: LayoutService
    ) { }

  userData: FormGroup;

  ngOnInit(): void {

    this.userData = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required]
    })
    if(this.authService.isLoggedIn()){
      this.authService.redirectUser()
    }
  }

  logIn(){
    if(!this.userData.valid){
      return false;
    }
    this.sharedService.visible(true)
    this.authService.login(this.userData).subscribe(
      data => {
        // console.log(data)
        if(data.masterHead.message == "Invalid Username or Password!!"){
          this.errorFlag = true;
        }
        else{
        localStorage.setItem("userId", data.masterDetails.user.id)
        localStorage.setItem("token", data.masterDetails.user.securityStamp)
        localStorage.setItem("roleId", data.masterDetails.user?.listroles[0])
        this.layoutService.setMenu(data.masterDetails.user.menuList);
        this.layoutService.setName(data.masterDetails.user.firstName+" "+data.masterDetails.user.middleName+" "+data.masterDetails.user.lastName)
        this.layoutService.setCount(data.masterDetails.user.filecount)
        localStorage.setItem("user", data.masterDetails.user.firstName+" "+data.masterDetails.user.middleName+" "+data.masterDetails.user.lastName)
        localStorage.setItem("count", data.masterDetails.user.filecount)
        this.authService.redirectUser();
        }
        this.sharedService.visible(false)
      },
      error => {
        // console.log(error);
      }
    )
  }

}
