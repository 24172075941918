
<a class="buttons" *ngIf="!authService.isLoggedIn()" mat-button routerLink="/login" routerLinkActive="active">Login</a>


<div *ngIf="authService.isLoggedIn()">

  <a class="buttons"  mat-button >{{layoutService.name}}
    <!-- ({{layoutService.count}}) -->
  </a>

  <a class="buttons" (click)="getUser()" (click)="authService.logoutUser()" mat-button >
    <span class="material-icons" style="font-size: 17px; border: 2px solid white; border-radius: 50%; padding: 5px;">
      logout
    </span>
  </a>

</div>
<!-- [ngbTooltip]="'User Name: '++ ', File count: '+files" triggers="click:blur" -->