<div class="container">

    <div class="user-box">
      <div class="row">
        <div class="col-9 my-auto">
          Role Master
        </div>
        <div class="col-3" style="text-align: right;" fxHide.lt-md="true">
          <button routerLink="/role-add" type="button" class="btn btn-primary"><b>+</b> New Role</button>
        </div>
      </div>
    </div>
    <br>

    <!-- <div style="text-align: left; width: 200px;">
      <mat-form-field style="margin-top: 13px;">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter..." #input>
      </mat-form-field>
      </div> -->
    <div class="mat-elevation-z8" style="text-align: left; box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);">

      <table mat-table [dataSource]="dataSource" matSort class="data-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%;"> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Status </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.status">
              Active
            </span>
            <span *ngIf="!row.status">
              Inactive
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Action </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color">
            <a (click)="role_edit(row.id, row)"><mat-icon class="edit-button">edit</mat-icon></a>
            <a (click)="role_display(row.id)"><mat-icon class="detail-button">menu</mat-icon></a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="rows"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>


    <br><br><br>

    <div style="text-align: left;">

      <button type="button" class="btn btn-danger btn-back" style="margin-left: 8px;">
        <mat-icon style="position: relative; top: 5px;">first_page</mat-icon> Back
      </button>
    </div>
  </div>
  <br><br><br>
  <br><br><br>
  <br>
