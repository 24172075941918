import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoleListComponent } from '../role-list/role-list.component';

export interface data {
  id: string;
}

@Component({
  selector: 'app-menu-delete',
  template: `
    <div style="padding: 5px; width: 300px;">
  <div mat-dialog-content>
    <img src="./assets/error.png" height="30px" width="30px">
    &nbsp; Do u want to delete this record?
  </div>
  <div mat-dialog-actions style="float: right;">
    <span style="padding: 3px;">
      <button class="btn btn-sm btn-primary" (click)="onNoClick()">No, Cancel</button>
    </span>
    <span style="padding: 3px;">
      <button class="btn btn-sm btn-secondary" [mat-dialog-close]="data.id" cdkFocusInitial>Yes, Delete it</button>
    </span>
  </div>
</div>

  `,
})
export class RoleDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RoleListComponent>, @Inject(MAT_DIALOG_DATA) public data: data) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
