import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogInComponent } from './auth/log-in/log-in.component';
import { ClientuploadComponent } from './client-upload/client-upload/clientupload.component';
import { PodNumberAddComponent } from './pod-number-master/pod-number-add/pod-number-add.component';
import { PodNumberDetailsComponent } from './pod-number-master/pod-number-details/pod-number-details.component';
import { PodNumberEditComponent } from './pod-number-master/pod-number-edit/pod-number-edit.component';
import { PodNumberListComponent } from './pod-number-master/pod-number-list/pod-number-list.component';
import { DataEntryForAdminComponent } from './pod/data-entry-for-admin/data-entry-for-admin.component';
import { DataSetComponent } from './pod/data-set/data-set.component';
import { MisReportsComponent } from './pod/mis-reports/mis-reports.component';
import { ReportsComponent } from './pod/reports/reports.component';
import { RoleAddComponent } from './role-master/role-add/role-add.component';
import { RoleDetailsComponent } from './role-master/role-details/role-details.component';
import { RoleEditComponent } from './role-master/role-edit/role-edit.component';
import { RoleListComponent } from './role-master/role-list/role-list.component';
import { UserAddComponent } from './user-master/user-add/user-add.component';
import { UserEditComponent } from './user-master/user-edit/user-edit.component';
import { UserListComponent } from './user-master/user-list/user-list.component';

const routes: Routes = [
  { path: 'data-set', component: DataSetComponent },
  { path: 'data-set-admin', component: DataEntryForAdminComponent},
  { path: 'login', component: LogInComponent },

  { path: 'user-list', component: UserListComponent },
  { path: 'user-add', component: UserAddComponent },
  { path: 'user-add/:id', component: UserEditComponent },

  { path: 'role-list', component: RoleListComponent },
  { path: 'role-add', component: RoleAddComponent },
  { path: 'role-add/:id', component: RoleEditComponent },
  { path: 'role-details/:id', component: RoleDetailsComponent },

  { path: 'pod-list', component: PodNumberListComponent},
  { path: 'pod-add', component: PodNumberAddComponent},
  { path: 'pod-add/:id', component: PodNumberEditComponent},
  { path: 'pod-details/:id', component: PodNumberDetailsComponent},

  { path: 'clientupload', component: ClientuploadComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'mis-reports', component: MisReportsComponent },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
