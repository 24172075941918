import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/shared.service';
import { DataDialogComponent } from '../data-dialog/data-dialog.component';
import { PodService } from '../pod.service';

@Component({
  selector: 'app-data-entry-for-admin',
  templateUrl: './data-entry-for-admin.component.html',
  styleUrls: ['./data-entry-for-admin.component.scss']
})
export class DataEntryForAdminComponent implements OnInit {

  constructor(
    private podService: PodService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) { }
  groupList = []


  searchData: FormGroup;
  podData: FormGroup;
  status = [];
  fileDataArray: FormGroup;
  IsEdit = false;

  ngOnInit(): void {

    this.sharedService.visible(true);
    this.searchData = this.fb.group({
      search: ["", Validators.required]
    })
    this.podData = this.fb.group({
      podNumber: ["", [Validators.required]],
      receivedDate: ["", Validators.required]
    })
    this.fileDataArray = this.fb.group({
      fileData: this.fb.array([])
    })
    this.podService.getStatusList().subscribe(
      data => {
        this.status = data.masterDetails?.listDocumentStatus
        this.sharedService.visible(false);
      }
    )

  }

  search_group() {
    if (!this.searchData.valid) {
      return false;
    }
    this.sharedService.visible(true);
    this.podService.getRecords(this.searchData).subscribe(
      data => {
        this.groupList = data.masterDetails.listClientInfo

        this.createFormArray();
        this.sharedService.visible(false);
      }
    )
  }
  edit_data(i) {
    // if (!this.podData.valid) {
    //   const dialogRef = this.dialog.open(DataDialogComponent);
    //   return false;
    // }
    let f = this.fileDataArray.get('fileData') as FormArray;
    this.podService.fileEdit(this.groupList[i].clientInfoId, f.at(i), this.podData).subscribe(
      data => {
        f.at(i).get("UCG").disable();
        f.at(i).get("DPN").disable();
        f.at(i).get("applicationForm").disable();
        f.at(i).get("CLIForm").disable();
        f.at(i).get("KYCCopy").disable();
        f.at(i).get("newRequestForm").disable();
      }
    )
    this.groupList[i].edit = false;
  }

  edit(index, category) {
    let f = this.fileDataArray.get('fileData') as FormArray;
    if (category == 'UCG') {
      let t = f.at(index).get("UCG").value
      for (let i = 0; i < this.groupList.length; i++) {
        if (!this.groupList[i].userId) {
          f.at(i).patchValue({ UCG: t })
        }

      }
    }
    if (category == 'DPN') {
      let t = f.at(index).get("DPN").value
      for (let i = 0; i < this.groupList.length; i++) {
        if (!this.groupList[i].userId) {
          f.at(i).patchValue({ DPN: t })
        }

      }
    }
    if (category == 'newRequestForm') {
      let t = f.at(index).get("newRequestForm").value
      if (this.groupList[index].product == "MTRP") {
        for (let i = 0; i < this.groupList.length; i++) {
          if (this.groupList[index].product == this.groupList[i].product && !this.groupList[i].userId) {
            f.at(i).patchValue({ newRequestForm: t })
          }
        }
      }

    }
  }

  act_edit(i) {
    let f = this.fileDataArray.get('fileData') as FormArray;
    f.at(i).get("UCG").enable();
    f.at(i).get("DPN").enable();
    f.at(i).get("applicationForm").enable();
    f.at(i).get("CLIForm").enable();
    f.at(i).get("KYCCopy").enable();
    f.at(i).get("newRequestForm").enable();
    this.groupList[i].edit = true;
  }

  check_status(id) {
    for (let i = 0; i < this.status.length; i++) {
      if (this.status[i].documentStatusId == id) {
        return this.status[i].docStatus
      }
    }
  }
  checkAllEditDone() {
    for (let i = 0; i < this.groupList.length; i++) {
      if (this.groupList[i].edit)
        return false;
    }
    return true;
  }



  get fileData() {
    return this.fileDataArray.get('fileData') as FormArray;
  }
  addFileData(data) {
    this.fileData.push(this.fb.group({
      UCG: {value: data.ucg, disabled: true},
      DPN: {value: data.dpn, disabled: true},
      applicationForm: {value: data.applicationForm, disabled: true},
      CLIForm: {value: data.cliForm, disabled: true},
      KYCCopy: {value: data.kycCopy, disabled: true},
      newRequestForm: {value: data.newRequestForm, disabled: true}
    }));
  }
  createFormArray() {
    this.fileDataArray = this.fb.group({
      fileData: this.fb.array([])
    })
    for (let i = 0; i < this.groupList.length; i++) {
      this.addFileData(this.groupList[i]);
    }
  }



}
