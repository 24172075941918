<div class="row" style="margin-bottom:15px;">
  <div class="col-md-12">
    <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" multiple style="display:none;" [accept]="extAccepts">


    <!-- {{progress}}% -->
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4"><button type="button" class="btn btn-info" (click)="file.click()">Select File</button>
        </div>
        <div class="col-md-8">
          <span class="upload" *ngIf="progress > 0">
            <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
          </span>
        </div>
      </div>
    </div>


    <span class="upload" *ngIf="message">
      {{message}}
    </span>
  </div>
</div>
