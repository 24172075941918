<div class="container" style="width: 100%; text-align: left;">
    <a mat-dialog-close type="button" class="btn modal-close" style="float: right;">
        <span style="color: rgb(255, 148, 148);" aria-hidden="true">&times;</span>
      </a>
    <form [formGroup]="boxData">
    <div class="row">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-4"><label for="name">Box Name</label></div>
                <div class="col-md-8">
                    <div class="form-group">
                        <input formControlName="name" class="form-control" id="name" placeholder="Box Name">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <button class="btn btn-sm btn-primary" (click)="add_box()">
                Add Box And Push Pouches
            </button>
        </div>
    </div>
    </form>
    <br><br>
    <h3>Recent Boxes</h3>
    <div class="row">
        <div class="col-md-4"><b>Boxes</b></div>
        <div class="col-md-4"><b>Pouches</b></div>
        <div class="col-md-4"></div>
    </div>
    <br>

    <form [formGroup]="boxEditData">
    <div class="row" *ngFor="let t of recentBoxes; let i=index;" formArrayName="box">
        <div class="col-md-8">
            <div class="row">
                <!-- <div class="col-6"><label for="name">{{t.cartonName}}</label></div>
                <div class="col-1"><label for="name">{{t.cartonName}}</label></div> -->

                <div class="col-6">
                    <input class="form-control" formControlName="{{i}}" style="position: absolute;" id="name" placeholder="Pouch Name" [readonly]="recentBoxOp[i]">
                </div>
                <div class="col-1 p-0">
                    <mat-icon *ngIf="recentBoxOp[i]" style="color: blue; cursor: pointer; font-size: 15px;" (click)="activate_readOnly(i)">edit</mat-icon>
                    <mat-icon *ngIf="!recentBoxOp[i]" style="color: blue; cursor: pointer; font-size: 18px;" (click)="editBox(i, t.cartonId)">done</mat-icon>
                </div>


                <div class="col-5">
                    <div class="form-group">
                        <input class="form-control" value={{t.pouchCount}} id="name" placeholder="Box Name" readonly>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <button class="btn btn-sm btn-primary" (click)="edit_box(t.cartonId, t.cartonName)">
                Push Boxes
            </button>
        </div>
    </div>
    </form>
</div>