<div class="container">
    <a mat-dialog-close type="button" class="btn modal-close" style="float: right;">
        <span style="color: rgb(255, 148, 148);" aria-hidden="true">&times;</span>
      </a>
    <form [formGroup]="fileData">
        <div class="row">
            <!-- <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>Group Number</label>
                    <input class="form-control" formControlName="groupNumber" readonly>
                </div>
            </div> -->
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>Client ID</label>
                    <input class="form-control" formControlName="clientID" readonly>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>Name</label>
                    <input class="form-control" formControlName="name" readonly>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>Loan Account</label>
                    <input class="form-control" formControlName="loanAccount" readonly>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>UCG</label>
                    <select class="form-control" formControlName="UCG">
                        <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>DPN</label>
                    <select class="form-control" formControlName="DPN">
                        <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>App Form</label>
                    <select class="form-control" formControlName="applicationForm">
                        <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>CLI Form</label>
                    <select class="form-control" formControlName="CLIForm">
                        <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>KYC Copy</label>
                    <select class="form-control" formControlName="KYCCopy">
                        <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" style="margin-top: 13px;">
                    <label>New Request Form</label>
                    <select class="form-control" formControlName="newRequestForm">
                        <option [value]="t.documentStatusId" *ngFor="let t of status">{{t.docStatus}}</option>
                    </select>
                </div>
            </div>
        </div>
        <button (click)="update()" style="margin-left: 2%;" class="btn btn-primary">Update</button>
    </form>
</div>