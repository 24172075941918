import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  public progress: number = 0;
  public message: string = "";
  IsEdit: boolean = false;
  userId: string = null;

  //  @Input() Allfiles: boolean;
  extAccepts = "application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*";

  @Output() public onUploadFinished = new EventEmitter();
  myAppUrl: string;
  constructor(private http: HttpClient, private sharedService: SharedService) {
    // this.myAppUrl = 'https://dataentryapi.mylocalo.com/';
    this.myAppUrl = environment.link;
    if (localStorage.getItem("Allfiles") == "0")
      this.extAccepts = "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,";

  }

  ngOnInit() {
    this.IsEdit = localStorage.getItem("IsEdit") == null ? false : true;
  }

  public uploadFile = (files: any) => {
    this.userId = localStorage.getItem("userId").toString();
    if (files.length === 0) {
      return;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    this.sharedService.visible(true)
    for (var i = 0; i < files.length; i++) {
      let fileToUpload = <File>files[this.IsEdit ? 0 : i];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('userId', this.userId);
      
      this.http.post(this.myAppUrl + 'api/upload', formData,
        {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          }),
          observe: 'events',
          reportProgress: true
        }
      )
        .subscribe(event => {
          
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            //this.message = 'Upload success.';
            this.onUploadFinished.emit(event.body);
          }
          
        });
    }
    this.sharedService.visible(false)
  }
}
