import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { RoleMasterService } from '../role-master.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {

  constructor(
    private loc: Location,
   private fb: FormBuilder,
   private roleService: RoleMasterService,
   private router: Router,
   private sharedService: SharedService,
   private route: ActivatedRoute
   ) { }
   roleData: FormGroup;

   goBack(){
   this.loc.back();
 }
 public id = parseInt(this.route.snapshot.paramMap.get('id'));
 dropdownList = [];
 selectedItems = [];
 dropdownSettings = {};
 ngOnInit(): void {
   this.roleData= this.fb.group({
     name: ['', Validators.required],
     selectedItems: [[]],
     status: [true],
   })

   this.roleService.GetMenuDropdown().subscribe(
     data => {
       this.dropdownList = []

       data.masterDetails.listMenu.forEach(element => {
         this.dropdownList.push({item_id: element.id, item_text: element.name})
         this.roleService.role["listMenu"].forEach(e => {
           if(element.id == e){
             this.selectedItems.push({item_id: element.id, item_text: element.name})
           }
         });
       });
       this.roleData.patchValue({name : this.roleService.role["name"]})
       this.roleData.patchValue({selectedItems : this.selectedItems})
     }
   )

   this.dropdownSettings = {
     singleSelection: false,
     idField: 'item_id',
     textField: 'item_text',
     selectAllText: 'Select All',
     unSelectAllText: 'UnSelect All',
     itemsShowLimit: 3,
     allowSearchFilter: true
   };
 }
 add_role(){
   this.sharedService.visible(true);
   this.roleService.editRole(this.id, this.roleData).subscribe(
     data => {
       this.router.navigate(['/role-list'])
       this.sharedService.visible(false);
     },
     error => {
      //  console.log(error)
       this.sharedService.visible(false);
     }
   )
 }

 get f() {return this.roleData.controls; }

 onItemSelect(item: any) {
   //console.log(item);
 }
 onSelectAll(items: any) {
   //console.log(items);
 }

}
